import { Typography } from "@mui/material";

const PropLabel = (props) => {
  return (
    <Typography variant={props.variant || "subtitle3"} component="div" sx={props.sx}>
      { props.children }
    </Typography>
  );
};

export default PropLabel;