import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export const PublicRoute = ({ children}) => {
  const { user } = useAuth();

  if (!!user) {
    // user is authenticated
    return <Navigate to="/main" />;
  }
  return children;
};