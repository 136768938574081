import { useNavigate } from 'react-router-dom';
import { Divider, Grid, ListItemIcon, ListItemText, ListSubheader, MenuItem, MenuList, Popover, Typography } from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import { AccountCircleRounded } from "@mui/icons-material";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import theme from '../theme';
import { useDialogs } from "../contexts/DialogContext";

export const AccountMenu = (props) => {
  const { openDialog } = useDialogs();
  const { user, logout } = useAuth();

  const navigate = useNavigate();

  const handleLogout = () => {
    logout(navigate);
  }

  return (
    <Popover id={props.id} anchorEl={props.anchorEl} open={props.open} onClose={props.close}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <MenuList component="nav" sx={{width: '100%'}} subheader={
          <ListSubheader sx={{py: 1}}>
            <Grid container justifyContent='start' alignItems='center' color="logo.main">
              <AdminPanelSettingsIcon sx={{mr: 1}} color="inherit" />
              <Typography variant="subtitle1" color="inherit">
                {user.userNm}
              </Typography>
            </Grid>
          </ListSubheader>
        }>
          
          <Divider sx={{mb: 1}} />

          <MenuItem onClick={() => {openDialog('account', {}); props.close()}}>
            <ListItemIcon><ManageAccountsIcon fontSize="small"/></ListItemIcon>
            <ListItemText sx={{ color: theme.palette.primary }}>내 정보</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => {openDialog('password', {}); props.close()}}>
            <ListItemIcon><LockPersonIcon fontSize="small"/></ListItemIcon>
            <ListItemText>비밀번호</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon><ExitToAppIcon fontSize="small"/></ListItemIcon>
            <ListItemText>로그아웃</ListItemText>
          </MenuItem>

        </MenuList>

    </Popover>
  );
}