import { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Dialog, DialogActions, Grid, Tooltip, Typography } from "@mui/material";
import PodoDialogTitle from "../components/PodoDialogTitle";
import PodoDialogContent from "../components/PodoDialogContent";
import DraggableHeader from "../components/DraggableHeader";
import { useUtils } from "../contexts/UtilContext";
import { useSnackbar } from "../contexts/SnackbarContext";
import { useForm } from "../hooks/useForm";
import AtchFile from "../components/AtchFile";

const AttachFiles = (props) => {
  const { api } = useUtils();
  const { showMessage } = useSnackbar();
  const node = props.node;

  const initialValues = {
    nodeId: '',
    nodeNm: '',
    imgAtchId: ''
  };

  const { values, handleChangeValues, resetForm, isValid } = useForm(initialValues, []);
  const [ isLoading, setIsLoading ] = useState(false);

  useEffect(() => {
    if (props.open) {
      resetForm(node);
    } else {
      resetForm(initialValues);
    }
  }, [props.open]);

  //적용
  const handleApply = () => {
    const param = {
      nodeId: values.nodeId,
      imgAtchId: values.imgAtchId
    };

    setIsLoading(true);
    api.post(`/api/nodes/${values.nodeId}/updateAtchId`, param).then(response => {
      showMessage(response?.message || '적용되었습니다.');
      props.handleClose();
      setIsLoading(false);
    }).catch(e => {
      if (e.response?.status === 401) props.handleClose();
      showMessage(`[${e.response?.status}] ${e.message}`, 'error');
      setIsLoading(false);
    });
  };

  return (
    <Dialog open={props.open} PaperComponent={DraggableHeader} aria-hidden={!props.open} fullWidth >
      <PodoDialogTitle text={'사진파일: ' + values.nodeNm } onClose={() => props.handleClose()}/>
      <PodoDialogContent>
        <AtchFile atchId={values.imgAtchId} title={props.title || "첨부파일"}
          onAtchIdChange={atchId => handleChangeValues({imgAtchId: atchId})}
          onAddFile={atchFileId => handleChangeValues({})}
          onDeleteFile={atchFileId => handleChangeValues({})}/>
      </PodoDialogContent>
      <DialogActions sx={{px: 3, mb: 1}}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle2">※ "적용" 해야 원격디스플레이에 반영됩니다.</Typography>
          <Box>
            <Tooltip title={!isValid ? "변경사항이 없습니다." : ""}><span>
              <Button variant="contained" disabled={!isValid || isLoading} onClick={handleApply}>
              {isLoading ? <CircularProgress size={24} color="inherit" /> : '적용'}
              </Button>
            </span>
            </Tooltip>
            <Button variant="contained" color="neutral" onClick={props.handleClose} sx={{ml: 2}}>취소</Button>
          </Box>
        </Grid>
      </DialogActions>
    </Dialog>
  )
};

export default AttachFiles;