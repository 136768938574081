import { Add, Delete } from "@mui/icons-material";
import { Box, Button, Container, Link, Typography } from "@mui/material";
import { useUtils } from "../contexts/UtilContext";
import { useDialogs } from "../contexts/DialogContext";
import { useSnackbar } from "../contexts/SnackbarContext";
import { useEffect, useState } from "react";
import AdminDataGrid from "../components/AdminDataGrid";

const AdminNodes = () => {
  const { api, confirmMessage } = useUtils();
  const { openDialog } = useDialogs();
  const { showMessage } = useSnackbar();

  const [ rows, setRows ] = useState([]);
  const [ selected, setSelected ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);

  const handleRead = () => {
    setIsLoading(true);
    api.post('/api/admin/nodes', {})
      .then(response => {
        setRows(response.data?.data);
        setIsLoading(false);
      })
      .catch(e => {
        showMessage(`[${e.response?.status}] ${e.message}`, 'error');
        setIsLoading(false);
      });
  };

  const handleAdd = () => {
    openDialog('adminNode', {
      title: '시설물 등록',
      nodeId: '',
      callback: handleRead
    });
  };

  const handleUpdate = (nodeId) => {
    openDialog('adminNode', {
      title: '시설물 수정',
      nodeId: nodeId,
      callback: handleRead
    });
  };

  const handleDelete = () => {
    if (selected.length === 0) {
      showMessage(`선택된 항목이 없습니다.`, 'warning');
      return;
    }

    confirmMessage('선택된 항목을 삭제하시겠습니까?',
      () => {
        setIsLoading(true);
        api.get(`/api/admin/nodes/${selected[0]}/delete`)
          .then(response => {
            handleRead();
            showMessage(response?.message || '삭제되었습니다.');
            setIsLoading(false);
          })
          .catch((e) => {
            showMessage(`[${e.response?.status}] ${e.message}`, 'error');
            setIsLoading(false);
          });
      }
    );
  };

  const handleDevice = (data) => {
    openDialog('adminDevices', {
      nodeId: data.nodeId,
      nodeNm: data.nodeNm,
      callback: handleRead
    });
  };

  useEffect(() => {
    handleRead();
  }, [])

  const columns = [
    { field: 'userId', headerName: '시설물ID', type: 'string', align: 'center', flex: 2, renderCell: data => (<Link component="button" onClick={() => handleUpdate(data.row.nodeId)}>{data.row.nodeId}</Link>)},
    { field: 'nodeNm',  headerName: '시설물명', type: 'string', flex: 3},
    { field: 'nodeTypeNm',  headerName: '시설물타입', type: 'string', align: 'center', flex: 2},
    { field: 'instlAddr',  headerName: '설치주소', type: 'string', flex: 5 },
    { field: 'grpCd',  headerName: '그룹코드', type: 'string', align: 'center', flex: 2 },
    { field: 'ordNo',  headerName: '정렬순서', type: 'number', align: 'center', flex: 1.5 },
    { field: 'useYn',  headerName: '사용여부', type: 'boolean', align: 'center', flex: 1.5},
    { field: 'dvcCnt',  headerName: '디바이스', type: 'number', align: 'center', flex: 1.5,
        renderCell: data => (<Link component="button" onClick={() => handleDevice(data.row)}>{data.row.dvcCnt}</Link>)},
  ]

  return (
    <Container>
      <Typography variant="h5" component="div">시설물관리</Typography>

      <Box display='flex' justifyContent='flex-end'>
        <Button variant="text" startIcon={<Add sx={{mr: -.5}}/>} onClick={handleAdd}>
          추가
        </Button>
        <Button variant="text" startIcon={<Delete sx={{mr: -.5}}/>} onClick={handleDelete}>
          삭제
        </Button>
      </Box>

      <Box>
        <AdminDataGrid
          rows={rows}
          columns={columns}
          getRowId={row => row.nodeId}
          density="compact"
          disableColumnMenu
          autoHeight
          loading={isLoading}
          rowSelectionModel={ selected } onRowSelectionModelChange={newSelected => setSelected(newSelected)}
        />
      </Box>
    </Container>
  )
}

export default AdminNodes;