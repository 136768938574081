import React, { createContext, useState, useContext } from 'react';
import MessageSnackbar from '../components/MessageSnackbar';
import { Slide } from '@mui/material';

const SnackbarContext = createContext();

export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  });

  const showMessage = (message, severity = 'info', anchorOrigin = { vertical: 'bottom', horizontal: 'center' }) => {
    setSnackbar({ open: true, message, severity, anchorOrigin });
  };

  const closeMessage = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  return (
    <SnackbarContext.Provider value={{ showMessage }}>
      {children}
      <MessageSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={closeMessage}
        anchorOrigin={snackbar.anchorOrigin}
        TransitionComponent={Slide}
      />
    </SnackbarContext.Provider>
  );
};
/*
showMessage('메시지', 'info', 3000); // 3초 동안 표시
showMessage('작업이 취소되었습니다', 'warning', 5000, {
  label: '되돌리기',
  onClick: handleUndo
});
showMessage('중요한 알림', 'error', null, null, { priority: 'high' });
*/