import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUtils } from "../contexts/UtilContext";

import { AppBar, Box, Button, IconButton, Toolbar, Tooltip, Typography, styled } from "@mui/material";
import { Settings, AccountCircleRounded, Fullscreen, FullscreenExit, Home } from "@mui/icons-material";
import { grey } from '@mui/material/colors';
import logoImage from '../assets/images/logo.png';

import { AccountMenu } from "../popups/AccountMenu";
import { useAuth } from "../contexts/AuthContext";

const drawerWidth = 0;
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
}));

const LogoImage = styled('img')(({ theme }) => ({
  height: 22,
  marginRight: 10,
  [theme.breakpoints.down('lg')]: {
    height: 17
  }
}));

export const Header = () => {
  const { user } = useAuth();
  const { fullscreen } = useUtils();
  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState(null);
  const accountMenuId = Boolean(accountMenuAnchorEl) ? 'account-menu' : undefined;

  const navigator = useNavigate();
  const location = useLocation();

  const handleAccountMenuOpen = (event) => {
    setAccountMenuAnchorEl(event.currentTarget);
  };

  const handleAccountMenuClose = () => {
    setAccountMenuAnchorEl(null);
  };

  const handleAdminPage = () => {
    navigator('/admin');
  }

  const handleMainPage = () => {
    navigator('/main');
  }
  
  return (
    <StyledAppBar position="fixed" sx={{ color: grey[600], bgcolor: grey[300] }}>
      <Toolbar variant="dense">
        <Box display='flex' alignItems="center">
          <LogoImage src={logoImage} alt="Logo" onClick={handleMainPage} sx={{cursor: 'pointer'}} />
          <Typography variant="h5" color="logo.main" fontWeight={600} lineHeight={1}>
            스마트 매니저
          </Typography>
        </Box>

        <Box display='flex' alignItems='center' marginLeft={4} flexGrow={1}>
          {(user.roles?.find(role => role === 'ROLE_ADMIN') && location.pathname.startsWith('/admin') ? [
            { name: '시설물관리', url: '/admin/nodes' },
            { name: '사용자관리', url: '/admin/users' },
          ] : [])
          .map((menu, index) => <Button variant='text' key={index} onClick={() => navigator(menu.url)} sx={{mx: 2}}>{menu.name}</Button>)
          }
        </Box>
        <Tooltip title={fullscreen.active ? "전체화면 취소" : "전체화면"}>
          <IconButton
            size='small'
            aria-label="fullscreen"
            onClick={() => fullscreen.toggle()}
            color="inherit">
            { fullscreen.active ? <FullscreenExit fontSize='large' /> : <Fullscreen fontSize='large' />}
          </IconButton>
        </Tooltip>

        { //관리자일 경우 관리자페이지 이동 버튼 표시
          user?.roles?.find(role => role === 'ROLE_ADMIN') ?
            location.pathname.startsWith('/main') ?
              <Tooltip title="시스템관리">
                <IconButton
                  size='small'
                  aria-label="admin page"
                  onClick={handleAdminPage}
                  color="inherit">
                  <Settings fontSize='large' />
                </IconButton>
              </Tooltip> :
              <Tooltip title="대시보드">
                <IconButton
                  size='small'
                  aria-label="main page"
                  onClick={handleMainPage}
                  color="inherit">
                  <Home fontSize='large' />
                </IconButton>
              </Tooltip>
          : <></>
        }
        <Tooltip title="내 정보">
          <IconButton
            size='small'
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleAccountMenuOpen}
            color="inherit"
          >
            <AccountCircleRounded fontSize='large' />
          </IconButton>
        </Tooltip>


        <AccountMenu id={accountMenuId} anchorEl={accountMenuAnchorEl} open={Boolean(accountMenuAnchorEl)} close={handleAccountMenuClose} />
      </Toolbar>
    </StyledAppBar>
  )
}