import { HelpOutline } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";

const HelpTooltip = ({ props, children }) => {
  return (
    <Tooltip title={<span style={{whiteSpace: 'pre-line'}}>{children}</span>} placement="right-start">
      <IconButton aria-label="help" size="small">
        <HelpOutline fontSize="small" sx={{color: grey[500]}} />
      </IconButton>
    </Tooltip>
  );
};

export default HelpTooltip;