import React from 'react';
import { Snackbar, Alert } from '@mui/material';

const MessageSnackbar = ({ open, message, severity, onClose, anchorOrigin }) => {
  return (
    <Snackbar 
      open={open} 
      autoHideDuration={6000} 
      onClose={onClose}
      anchorOrigin={anchorOrigin || { vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default MessageSnackbar;