import { ToggleButton } from "@mui/material";
import { grey } from "@mui/material/colors";

const PropToggleButton = ( props ) => {

  return (
    <ToggleButton {...props} sx={{
      px: 2,
      py: .5,
      color: grey[800],
      "&.Mui-selected": {
        color: 'primary.contrastText',
        backgroundColor: 'primary.main'
      },
      "&.Mui-selected:hover": {
        backgroundColor: 'primary.dark'
      }
    }}>
      {props.children}
    </ToggleButton>
  );
};

export default PropToggleButton;