import { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Dialog, DialogActions, Grid, MenuItem } from "@mui/material";
import PodoDialogTitle from "../components/PodoDialogTitle";
import PodoDialogContent from "../components/PodoDialogContent";
import DraggableHeader from "../components/DraggableHeader";
import { useUtils } from "../contexts/UtilContext";
import { useSnackbar } from "../contexts/SnackbarContext";
import { useForm } from "../hooks/useForm";
import CompactTextField from "../components/CompactTextField";
import { useDialogs } from "../contexts/DialogContext";
import { useCode } from "../contexts/CodeContext";

const WthrSetup = (props) => {
  const { awsStns } = useCode();
  const { openDialog } = useDialogs();
  const { api } = useUtils();
  const { showMessage } = useSnackbar();
  const node = props.node;
  const dvc = props.dvc;
  const data = dvc.data;

  const { values, handleChange, handleChangeValues, resetForm, isValid } = useForm({
    nx: '',
    ny: ''
  }, []);
  const [ isLoading, setIsLoading ] = useState(false);

  useEffect(() => {
    if (props.open) {
      resetForm({...data})
    }
  }, [props.open]);

  //적용
  const handleApply = () => {
    const param = {
      nx: values.nx,
      ny: values.ny
    };

    setIsLoading(true);
    api.post(`/api/devices/${dvc.dvcId}/update`, param).then(response => {
      showMessage(response?.message || '적용되었습니다.');
      props.handleClose();
      setIsLoading(false);
    }).catch(e => {
      if (e.response?.status === 401) props.handleClose();
      showMessage(`[${e.response?.status}] ${e.message}`, 'error');
      setIsLoading(false);
    });
  }

  const handleWthrCoodSearch = () => {
    openDialog('wthrCoodSearch', {
      node: node,
      callback: (data) => {
        handleChangeValues({nx: data?.nx, ny: data?.ny});
      }
    });
  };

  return (
    <Dialog open={props.open} PaperComponent={DraggableHeader}>
      <PodoDialogTitle text={'설정: ' + dvc.dvcNm} onClose={() => props.handleClose()}/>
      <PodoDialogContent sx={{mb: 2}}>
        <Grid container spacing={2} justifyContent="space-evenly">
          <Grid item xs={12} >
            <Box display="flex" justifyContent="center" alignItems="center">
              <CompactTextField name='nx' label="좌표(X)"
                select
                value={values.nx || ''}
                onChange={handleChange}
                size='small'
                sx={{ minWidth: 70, mr: 2}}
                margin="dense"
                autoComplete="off" >
                { [21,28,33,38,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,126,127,144].map((item, index) => <MenuItem key={index} value={item} dense>{item}</MenuItem>)}
              </CompactTextField>
              <CompactTextField name='ny' label="좌표(Y)"
                select
                value={values.ny || ''}
                onChange={handleChange}
                size='small'
                sx={{ minWidth: 70, mr: 2}}
                margin="dense"
                autoComplete="off" >
                { [8,32,33,34,35,36,37,38,39,48,50,53,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125,126,127,128,129,130,131,132,133,134,135,136,137,138,139,140,141,142,143,144,145,146,147].map((item, index) => <MenuItem key={index} value={item} dense>{item}</MenuItem>)}
              </CompactTextField>
              <Button variant="contained" color="info" size="small" onClick={handleWthrCoodSearch}>
                좌표 검색
              </Button>
            </Box>
          </Grid>
        </Grid>
      </PodoDialogContent>
      <DialogActions>
        <Button variant="contained" disabled={!isValid || isLoading} onClick={handleApply}>
        {isLoading ? <CircularProgress size={24} color="inherit" /> : '적용'}
        </Button>
        <Button variant="contained" color="neutral" onClick={props.handleClose}>취소</Button>
      </DialogActions>
    </Dialog>
  )
};

export default WthrSetup;