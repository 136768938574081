import { useState, useEffect } from "react";
import { useUtils } from "../contexts/UtilContext";
import { useCode } from "../contexts/CodeContext";
import CompactTextField from "../components/CompactTextField";
import { isEmpty, isNotEmpty, isEmail } from "../utils/validations";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, IconButton, InputAdornment, MenuItem, Switch, Typography } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';

import CompactFormControlLabel from "../components/CompactFormControlLabel";
import DraggableHeader from "../components/DraggableHeader";
import { useSnackbar } from "../contexts/SnackbarContext";
import { useForm } from "../hooks/useForm";
import PodoDialogTitle from "../components/PodoDialogTitle";

const AdminUser = (props) => {
  const { showMessage } = useSnackbar();
  const { api, confirmMessage} = useUtils();
  const { codes } = useCode();
  
  const mode = isNotEmpty(props.userId) ? 'U' : 'C';

  const initialValues = {
    userId: '',
    userNm: '',
    userTypeCd: 'ROLE_USER',
    apikey: '',
    pwd: '',
    pwd2: '',
    cmpnyNm: '',
    email: '',
    telNo: '',
    useYn: true
  };

  const { values, setValues, errors, touched, handleChange, handleChangeValues, resetForm, isValid } = useForm(
    initialValues,
    [
      ({userId}) => isNotEmpty(userId) || {userId: '사용자ID를 입력해주세요.'},
      ({userNm}) => isNotEmpty(userNm) || {userNm: '사용자명을 입력해주세요.'},
      ({apikey, userTypeCd}) => userTypeCd !== 'ROLE_API' || isNotEmpty(apikey) || {apikey: 'API KEY를 입력해주세요.'},
      ({pwd, userTypeCd}) => mode !== 'C' || userTypeCd === 'ROLE_API' || isNotEmpty(pwd) || {pwd: '비밀번호를 입력해주세요.'},
      ({pwd, pwd2, userTypeCd}) => mode !== 'C' || userTypeCd === 'ROLE_API' || isEmpty(pwd) || pwd === pwd2 || {pwd2: '비밀번호가 일치하지 않습니다.'},
      ({email}) => isEmpty(email) || isEmail(email) || {email: '유효한 이메일 주소를 입력해주세요.'},
    ]
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.open && mode === 'U') {
      api.get(`/api/admin/users/${props.userId}`, {})
        .then(response => {
          setValues({...values, ...response.data?.data});
        })
        .catch(e => {
          if (e.response?.status === 401) props.handleClose();
          showMessage(`[${e.response?.status}] ${e.message}`, 'error')
        });
    } else {
      resetForm(initialValues);
    }
  }, [props.open]);

  /* 저장 */
  const handleSave = () => {
    //수정
    if (mode === 'U') {
      confirmMessage('변경하시겠습니까?', () => {
        setIsLoading(true);
        api.post(`/api/admin/users/${props.userId}/update`, values)
          .then(response => {
            showMessage('변경되었습니다.');
            props.handleClose(true);
            setIsLoading(false);
          }).catch((e) => {
            if (e.response?.status === 401) props.handleClose();
            showMessage(`[${e.response?.status}] ${e.message}`, 'error')
            setIsLoading(false);
          });
      });
    
    } else {
      confirmMessage('사용자를 등록하시겠습니까?', () => {
        setIsLoading(true);
        api.post(`/api/admin/users/create`, values)
          .then(response => {
            showMessage('등록되었습니다.');
            props.handleClose(true);
            setIsLoading(false);
          })
          .catch((e) => {
            if (e.response?.status === 401) props.handleClose();
            showMessage(`[${e.response?.status}] ${e.message}`, 'error');
            setIsLoading(false);
          });
      });
    }
  };

  /* 비밀번호 초기화 */
  const handlePwdReset = () => {
    confirmMessage("비밀번호를 초기화하시겠습니까?", () => {
        api.post(`/api/admin/users/${values.userId}/password/reset`, {})
          .then(response => {
            showMessage('처리되었습니다.');
            setIsLoading(false);
          })
          .catch(e => {
            if (e.response?.status === 401) props.handleClose();
            showMessage(`[${e.response.status}] ${e.message}`, 'error')
            setIsLoading(false);
          });
    });
  }

  const handleCreateApikey = () => {
    handleChangeValues({apikey: crypto.randomUUID()});
  }

  return (
    <Dialog open={props.open} PaperComponent={DraggableHeader}>
      <PodoDialogTitle text={props.title} onClose={() => props.handleClose()}/>
      <DialogContent sx={{mt: 2, pb: 0}}>
        <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={6}>
            <CompactTextField name='userId' label="사용자ID"
              value={values.userId}
              onChange={handleChange}
              size='small'
              sx={{}}
              error={touched.userId && !!errors.userId}
              helperText={(touched.userId && errors.userId) || ' '}
              FormHelperTextProps={{sx: {minHeight: '1.5em'}}}
              inputProps={{ maxLength: 20 }}
              margin="dense"
              required
              fullWidth
              disabled={mode === 'U'}
              autoComplete="new-password" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CompactTextField name="userNm" label="사용자명"
              value={values.userNm}
              onChange={handleChange}
              size='small'
              sx={{}}
              error={touched.userNm && !!errors.userNm}
              helperText={(touched.userNm && errors.userNm) || ' '}
              FormHelperTextProps={{sx: {minHeight: '1.5em'}}}
              inputProps={{ maxLength: 30 }}
              margin="dense"
              required
              fullWidth
              autoComplete="new-password" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CompactTextField name='userTypeCd' label="사용자유형"
               select
               value={values.userTypeCd}
               onChange={handleChange}
               size='small'
               sx={{}}
               error={touched.userTypeCd && !!errors.userTypeCd}
               helperText={(touched.userTypeCd && errors.userTypeCd) || ' '}
               FormHelperTextProps={{sx: {minHeight: '1.5em'}}}
               inputProps={{ maxLength: 30 }}
               margin="dense"
               required
               fullWidth
               autoComplete="new-password">
              { codes.UserType?.map((code, index) => <MenuItem key={index} value={code.code} disabled={!code.active} dense>{code.label}</MenuItem>)}
            </CompactTextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CompactTextField name="apikey" label="API KEY"
              value={values.apikey}
              onChange={handleChange}
              size='small'
              sx={{}}
              error={touched.apikey && !!errors.apikey}
              helperText={(touched.apikey && errors.apikey) || ' '}
              FormHelperTextProps={{sx: {minHeight: '1.5em'}}}
              inputProps={{ maxLength: 45 }}
              margin="dense"
              required={values.userTypeCd === 'ROLE_API'}
              fullWidth
              disabled={values.userTypeCd !== 'ROLE_API'}
              InputProps={{ endAdornment: <InputAdornment position="end"><IconButton onClick={handleCreateApikey} onMouseDown={e => e.preventDefault()} edge="end" disabled={values.userTypeCd !== 'ROLE_API'} title="자동생성"><RefreshIcon /></IconButton></InputAdornment>}} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CompactTextField name="pwd" label="비밀번호"
              value={mode === 'C' ? values.pwd : '               '}
              onChange={handleChange}
              size="small"
              margin="dense"
              sx={{}}
              error={touched.pwd && !!errors.pwd}
              helperText={(touched.pwd && errors.pwd) || ' '}
              FormHelperTextProps={{sx: {minHeight: '1.5em'}}}
              type="password"
              required={mode === 'C' && values.userTypeCd !== 'ROLE_API'}
              autoFocus
              inputProps={{ maxLength: 30 }}
              autoComplete="new-password"
              fullWidth
              disabled={mode === 'U' || values.userTypeCd === 'ROLE_API'} />
          </Grid>
          <Grid item xs={12} sm={6}>
            {mode === 'U' && values.userTypeCd !== 'ROLE_API'
              ? <Box display='flex' justifyContent="flex-start" alignItems='center' sx={{ mt: '.5em', mb: '1.5em'}}>
                  <Button variant="contained" color="info" size="small" onClick={handlePwdReset}>
                    비밀번호 초기화
                  </Button>
                  <Typography variant="subtitle2" color="inherit" sx={{ ml: 2}} >
                    ※ 아이디와 동일
                  </Typography>
                </Box>
              : <CompactTextField name="pwd2" label="비밀번호확인"
                  value={values.pwd2}
                  onChange={handleChange}
                  size="small"
                  margin="dense"
                  sx={{}}
                  error={touched.pwd2 && !!errors.pwd2}
                  helperText={(touched.pwd2 && errors.pwd2) || ' '}
                  FormHelperTextProps={{sx: {minHeight: '1.5em'}}}
                  type="password"
                  required={mode === 'C' && values.userTypeCd !== 'ROLE_API'}
                  autoFocus
                  inputProps={{ maxLength: 30 }}
                  autoComplete="new-password"
                  fullWidth
                  disabled={mode === 'U' || values.userTypeCd === 'ROLE_API'} />
            }
          </Grid>
          <Grid item xs={12} sm={6}>
            <CompactTextField name="cmpnyNm" label="소속기관명"
              value={values.cmpnyNm}
              onChange={handleChange}
              size='small'
              sx={{}}
              error={touched.cmpnyNm && !!errors.cmpnyNm}
              helperText={(touched.cmpnyNm && errors.cmpnyNm) || ' '}
              FormHelperTextProps={{sx: {minHeight: '1.5em'}}}
              inputProps={{ maxLength: 100 }}
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CompactTextField name="email" label="이메일"
              value={values.email}
              onChange={handleChange}
              size='small'
              sx={{}}
              error={isNotEmpty(values.email) && touched.email && !!errors.email}
              helperText={(isNotEmpty(values.email) && touched.email && errors.email) || ' '}
              FormHelperTextProps={{sx: {minHeight: '1.5em'}}}
              inputProps={{ inputMode: 'email', maxLength: 100 }}
              margin="dense"
              fullWidth
              />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CompactTextField name="telNo" label="전화번호"
              value={values.telNo}
              onChange={handleChange}
              size='small'
              sx={{}}
              error={touched.telNo && !!errors.telNo}
              helperText={(touched.telNo && errors.telNo) || ' '}
              FormHelperTextProps={{sx: {minHeight: '1.5em'}}}
              inputProps={{ maxLength: 100 }}
              margin="dense"
              fullWidth
              />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CompactFormControlLabel
              control={<Switch name="useYn" checked={!!values.useYn} onChange={handleChange} />}
              label="사용여부" sx={{mt: 0.5}} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{pr: 3, pb: 2}}>
        <Button variant="contained" disabled={!isValid || isLoading} onClick={handleSave}>
        {isLoading ? <CircularProgress size={24} color="inherit" /> : '저장'}
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default AdminUser;