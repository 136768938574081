import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

const CodeContext = createContext();

export const useCode = () => {
  return useContext(CodeContext);
};

export const CodeProvider = ({ children }) => {
  const [codes, setCodes] = useState({});
  const [awsStns, setAwsStns] = useState([]);

  const readCode = async () => {
    try {
      const response = await axios.get("/api/common/codes");
      const data = response.data;
      setCodes(data?.data);

    } catch (e) {
      console.error (e);
      setCodes({});
    }
  }
  
  const readAwsStns = async () => {
    try {
      const response = await axios.post("/api/common/awsStns", {});
      const data = response.data;
      setAwsStns(data?.data);

    } catch (e) {
      console.error (e);
      setAwsStns([]);
    }
  }

  useEffect(() => {
    readCode();
    readAwsStns();
  }, []);

  return (
    <CodeContext.Provider value={{codes, readCode, awsStns}}>
      {children}
    </CodeContext.Provider>
  );
};