import { ArrowBack, Home } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Error403 = () => {
  const navigate = useNavigate();

  return (
    <Grid container align="center" justifyContent="center" direction="column" sx={{mt: 20}}>
      <Grid item xs={12}>
        <Typography variant="h1" sx={{color: '#ff8844', fontSize: 150}}>403</Typography>
        <Typography variant="h4" sx={{color: '#666'}}>접근 권한이 없는 페이지입니다.</Typography>
        <Typography sx={{color: '#666'}}>관리자에게 문의해주세요.</Typography>
      </Grid>
      <Grid item xs={12}>
        <Button size="large" startIcon={<ArrowBack/>} sx={{fontSize: 20}} onClick={() => navigate(-1)}>Back</Button>
        <Button size="large" startIcon={<Home/>} sx={{fontSize: 20}} onClick={() => navigate('/')}>Home</Button>
      </Grid>
    </Grid>
  )
}

export default Error403;