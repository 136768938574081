import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const theme = responsiveFontSizes(createTheme ({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1280,
            xl: 1920
        }
    },
    palette: {
        primary: {
            main: '#2c61ad',
            light: '#649dde',
            dark: '#234e99',
            contrastText: '#fff'
        },
        secondary: {
            main: '#eb6129',
            light: '#ff8400',
            dark: '#d94316',
            contrastText: '#fff'
        },
        error: {
            main: '#b2351b',
            light: '#fc7452',
            dartk: '#a3270f',
            contrastText: '#000'
        },
        neutral: {
            main: '#616161',
            light: '#bdbdbd',
            dark: '#424242',
            contrastText: '#fff'
        },
        logo: {
            main: '#00859d'
        }
    },
    typography: {
        fontFamily: ['Pretendard', 'Noto Sans KR'].join(','),
        unit: {
            fontSize: '0.7em',
        },
        h7: {
            fontWeight: 500,
            fontSize: "1rem",
            lineHeight: 1.6,
            letterSpacing: "0.0075em"
        },
        subtitle3: {
            fontWeight: 400,
            fontSize: '0.75rem',
            lineHeight: 1.2
        }
    }
}));

export default theme;