import { useEffect, useState } from "react";
import { Add, Delete } from "@mui/icons-material";
import { Box, Button, Container, Link, Typography } from "@mui/material";
import { useDialogs } from "../contexts/DialogContext";
import AdminDataGrid from "../components/AdminDataGrid";
import { useUtils } from "../contexts/UtilContext";
import { useSnackbar } from "../contexts/SnackbarContext";

const AdminUsers = () => {
  const { api, confirmMessage } = useUtils();
  const { openDialog } = useDialogs();
  const { showMessage } = useSnackbar();

  const [ rows, setRows ] = useState([]);
  const [ selected, setSelected ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);

  const handleRead = () => {
    setIsLoading(true);
    api.post('/api/admin/users', {})
      .then(response => {
        setRows(response.data?.data);
        setIsLoading(false);
      })
      .catch((e) => {
        showMessage(`[${e.response?.status}] ${e.message}`, 'error');
        setIsLoading(false);
      });
  };

  const handleAdd = () => {
    openDialog('adminUser', {
      title: '사용자 등록',
      userId: '',
      callback: handleRead
    });
  };

  const handleUpdate = (userId) => {
    openDialog('adminUser', {
      title: '사용자 정보 수정',
      userId: userId,
      callback: handleRead
    });
  };

  const handleDelete = () => {
    if (selected.length === 0) {
      showMessage(`선택된 항목이 없습니다.`, 'warning');
      return;
    }

    confirmMessage('선택된 항목을 삭제하시겠습니까?',
      () => {
        setIsLoading(true);
        api.get(`/api/admin/users/${selected[0]}/delete`)
          .then(response => {
            handleRead();
            showMessage(response?.message || '삭제되었습니다.');
            setIsLoading(false);
          })
          .catch((e) => {
            showMessage(`[${e.response?.status}] ${e.message}`, 'error');
            setIsLoading(false);
          });
      }
    );
  }

  useEffect(() => {
    handleRead();
  }, [])

  const columns = [
    { field: 'userId', headerName: '사용자ID', type: 'string', align: 'center', flex: 2, renderCell: data => (<Link component="button" onClick={() => handleUpdate(data.row.userId)}>{data.row.userId}</Link>)},
    { field: 'userNm',  headerName: '사용자명', type: 'string', align: 'center', flex: 3},
    { field: 'cmpnyNm',  headerName: '소속기관명', type: 'string', align: 'center', flex: 3},
    { field: 'email',  headerName: '이메일', type: 'string', flex: 4},
    { field: 'telNo',  headerName: '전화번호', type: 'string', flex: 3 },
    { field: 'useYn',  headerName: '사용여부', type: 'boolean', align: 'center', flex: 2},
    { field: 'lastLoginDtm',  headerName: '최종로그인', type: 'string', align: 'center', flex: 4},
    { field: 'chgUserNm',  headerName: '수정자', type: 'string', align: 'center', flex: 2},
    { field: 'chgDtm',  headerName: '수정일자', type: 'string', align: 'center', flex: 4}
  ]
  
  return (
    <Container>
      <Typography variant="h5" component="div">사용자관리</Typography>
      <Box display='flex' justifyContent='flex-end'>
        <Button variant="text" startIcon={<Add sx={{mr: -.5}}/>} onClick={handleAdd}>
          추가
        </Button>
        <Button variant="text" startIcon={<Delete sx={{mr: -.5}}/>} onClick={handleDelete}>
          삭제
        </Button>
      </Box>

      <Box>
        <AdminDataGrid
          rows={rows}
          columns={columns}
          getRowId={row => row.userId}
          density="compact"
          disableColumnMenu
          autoHeight
          loading={isLoading}
          rowSelectionModel={ selected } onRowSelectionModelChange={newSelected => setSelected(newSelected)}
        />
      </Box>

    </Container>
  );
};

export default AdminUsers;