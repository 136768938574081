import { Card, Grid } from "@mui/material";
import Auto from "./Auto";
import DeviceCardHeader from "./DeviceCardHeader";
import DeviceCardContent from "./DeviceCardContent";
import PropLabel from "./PropLabel";
import PropValue from "./PropValue";
import Color from "./Color";
import { useUtils } from "../../contexts/UtilContext";

const Fdld = (props) => {
  const { timeString } = useUtils();
  const node = props.node;
  const dvc = props.dvc;
  const data = dvc.data;

  return (
    <Grid item xs={3}>
      <Card>
        <DeviceCardHeader node={node} dvc={dvc} on={ !!data?.colr && data?.colr !== "000000" } setup />
        <DeviceCardContent>
          <Grid container alignItems='start' justifyContent="space-evenly">
            <Grid item xs={3} textAlign='center'>
              <PropLabel>제어모드</PropLabel>
              <Auto value={data.auto} size="small" />
            </Grid>
            <Grid item xs={3} textAlign='center'>
              <PropLabel>색상</PropLabel>
              <Color color={"#" + (data?.colr || "000000")} />
            </Grid>
            <Grid item xs={6} textAlign='center'>
              <PropLabel>운영시간</PropLabel>
              <PropValue>{timeString(data.oprtStHm, data.oprtEdHm)}</PropValue>
            </Grid>
          </Grid>
        </DeviceCardContent>
      </Card>
    </Grid>
  );
}

export default Fdld;