import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/fonts/pretendard.css';
import './assets/styles/index.css';
import './assets/styles/app.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ko';
import { SnackbarProvider } from './contexts/SnackbarContext';
import theme from './theme';
import { AuthProvider } from './contexts/AuthContext';
import axios from "axios";
import { CodeProvider } from './contexts/CodeContext';

//wijmo.setLicenseKey('localhost|podo.nthcompany.com,541734252846775#B0hNLbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRZ5VGBTb7dVWV5mMXVEM4BXaUBVZvs6KQRHNFZ6bEBVV6wWRHdDWZtiMxwUZhJ5U0RndoNWaqBjevATU9VkQkVXd0NHThN6UEZkb6kzK5NWW5JEMNplcKFzcxUEc8AjdxIjclFVM6knZ4ZUN6QDTMlVVYtUNQRnNoNzVrhjN79Ue4sSZ6I5dpJlV7hXNRhnRhVHczgHcxdXYxMjT49EOXBnUaVVVPh5MYR4KNdjUBVXZ5Z7KkNzRYdFbPRFeJFUQ0lERyo4bpF4c8YkWKF5KtBDanpnQM3CZ4kUbKF4MRRVOKRkd8ZzL9FmStpmU7I4LOV6K0h7ZvVnQzNlZtRnTpd4N9IEasJzYWlHaaNlNTNkRrQETRdESuFGW7MUaWJ4YHVFRwIzQ6tyTjJUQzEkTUFnVIF6R436a5YVVH5GNx2mWWlDZPBjRKd7br2ETwcXdtJVS954YiojITJCLiUjR4QTOGNTMiojIIJCLzYzM9kTMzkTO0IicfJye=#Qf35VfikEMyIlI0IyQiwiIu3Waz9WZ4hXRgACdlVGaThXZsZEIv5mapdlI0IiTisHL3JSNJ9UUiojIDJCLi86bpNnblRHeFBCIyV6dllmV4J7bwVmUg2Wbql6ViojIOJyes4nILdDOIJiOiMkIsIibvl6cuVGd8VEIgc7bSlGdsVXTg2Wbql6ViojIOJyes4nI4YkNEJiOiMkIsIibvl6cuVGd8VEIgAVQM3EIg2Wbql6ViojIOJyes4nIzMEMCJiOiMkIsISZy36Qg2Wbql6ViojIOJyes4nIVhzNBJiOiMkIsIibvl6cuVGd8VEIgQnchh6QsFWaj9WYulmRg2Wbql6ViojIOJyebpjIkJHUiwiI4QzNwcDMgcjM7ADNyAjMiojI4J7QiwiIt36YuknbhBXbvNGa49mLvR6bwxCdz3GasF6YvxmI0IyctRkIsISeuFGct36YggGduJiOiEmTDJCLiUzN7YDN8ITNyQzM7EDN5IiOiQWSiwSfdtlOicGbmJCLiIjdxIDMyIiOiIXZcJ5L');

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
            <CodeProvider>
              <SnackbarProvider>
                <AuthProvider>
                      <App />
                </AuthProvider>
              </SnackbarProvider>
            </CodeProvider>
          </LocalizationProvider>
      </ThemeProvider>
  </React.StrictMode>
);