import { useState, useLayoutEffect } from "react";
import { useUtils } from "../contexts/UtilContext";
import CompactTextField from "../components/CompactTextField";
import { isNotEmpty, isEmpty } from '../utils/validations';

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import LockPersonIcon from '@mui/icons-material/LockPerson';
import DraggableHeader from "../components/DraggableHeader";
import { useSnackbar } from "../contexts/SnackbarContext";
import { useForm } from "../hooks/useForm";

const Password = (props) => {
  const { api, confirmMessage } = useUtils();
  const { showMessage } = useSnackbar();
  const initialValues = {
    oldPwd: '',
    pwd: '',
    pwd2: ''
  };

  const { values, setValues, errors, touched, handleChange, resetForm, isValid } = useForm(
    initialValues,
    [
      ({oldPwd}) => isNotEmpty(oldPwd) || {oldPwd: '현재 비밀번호를 입력해주세요.'},
      ({pwd}) => isNotEmpty(pwd) || {pwd: '새 비밀번호를 입력해주세요.'},
      ({pwd, pwd2}) => isEmpty(pwd) || pwd === pwd2 || {pwd2: '새 비밀번호가 일치하지 않습니다.'}
    ]
  );
  const [isLoading, setIsLoading] = useState(false);

  useLayoutEffect(() => {
    props.open ? setValues(initialValues) : resetForm(initialValues);
  }, [props.open]);

  /* 저장 */
  const handleSave = () => {
    confirmMessage('변경하시겠습니까?', () => {
      setIsLoading(true);
      api.post(`/api/account/password/update`, values)
        .then((response) => {
          setIsLoading(false);
          showMessage('변경되었습니다.');
          props.handleClose();
        }).catch((e) => {
          setIsLoading(false);
          if (e.response?.status === 401) props.handleClose();
          showMessage(`[${e.response.status}] ${e.message}`, 'error');
        });
    });
  };

  return (
    <Dialog open={props.open} PaperComponent={DraggableHeader}>
      <DialogTitle sx={{ py:1, bgcolor: '#2c61ad', color: '#fff'}} display='flex' alignItems='center'>
        <LockPersonIcon sx={{color: '#fff', mr: 1}}/>
        비밀번호 변경
        <IconButton aria-label="close" onClick={props.handleClose} title="닫기" sx={{ position: 'absolute', right: 4, top: 4, color: grey[300], }}><Close /></IconButton>
      </DialogTitle>
      <DialogContent sx={{mt: 2, pb: 0}}>
        <Grid container columnSpacing={2}>
          <Grid item xs={12}>
            <CompactTextField name='oldPwd' label="현재 비밀번호"
              value={values.oldPwd}
              onChange={handleChange}
              size="small"
              margin="dense"
              sx={{}}
              error={touched.oldPwd && !!errors.oldPwd}
              helperText={(touched.oldPwd && errors.oldPwd) || ' '}
              FormHelperTextProps={{sx: {minHeight: '1.5em'}}}
              type="password"
              required
              autoFocus
              inputProps={{ maxLength: 30 }}
              autoComplete="new-password"
              fullWidth />
          </Grid>
          <Grid item xs={12}>
            <CompactTextField name="pwd" label="새 비밀번호"
              value={values.pwd}
              onChange={handleChange}
              size="small"
              margin="dense"
              sx={{}}
              error={touched.pwd && !!errors.pwd}
              helperText={(touched.pwd && errors.pwd) || ' '}
              FormHelperTextProps={{sx: {minHeight: '1.5em'}}}
              type="password"              
              required
              inputProps={{ maxLength: 30 }}
              autoComplete="new-password"
              fullWidth/>
          </Grid>
          <Grid item xs={12}>
            <CompactTextField name="pwd2" label="새 비밀번호 확인"
              value={values.pwd2}
              onChange={handleChange}
              size="small"
              margin="dense"
              sx={{}}
              error={touched.pwd2 && !!errors.pwd2}
              helperText={(touched.pwd2 && errors.pwd2) || ' '}
              FormHelperTextProps={{sx: {minHeight: '1.5em'}}}
              type="password"              
              required
              inputProps={{ maxLength: 30 }}
              autoComplete="new-password"
              fullWidth/>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{pr: 3, pb: 2}}>
        <Button variant="contained" disabled={!isValid || isLoading } onClick={handleSave}>
        {isLoading ? <CircularProgress size={24} color="inherit" /> : '저장'}
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default Password;