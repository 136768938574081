import { renderTimeViewClock, TimePicker } from "@mui/x-date-pickers";
import CompactTextField from "../CompactTextField";

const PropTimePicker = ( props ) => {
  return (
    <TimePicker name={props.name} label={props.label}
      value={props.value}
      format="HH:mm"
      onChange={value => props.onChange({target: {name: props.name, value: value }})}
      ampm={false}
      slots={{ textField: CompactTextField }}
      slotProps={{ textField: {
        size: 'small',
        margin: 'dense',
        FormHelperTextProps: {sx: {minHeight: '1.5em'}},
        sx: {maxWidth: 125}
      }}}
      viewRenderers={{
        hours: renderTimeViewClock,
        minutes: renderTimeViewClock
      }}
      disabled={props.disabled || false} />
  )
};

export default PropTimePicker;