import { useState, useCallback } from 'react';

export const useForm = (initialState, validations) => {
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [isValid, setValid] = useState(false);

  const handleChange = useCallback(event => {
    const {name, value, checked, type} = event.target;
    const newValues = {...values, [name]: (type === 'checkbox') ? checked : value};

    setTouched(prev => ({ ...prev, [name]: true }));
    setValues(newValues);

    const {isValid, errors} = validateForm(newValues);
    setValid(isValid);
    setErrors(errors);
  }, [values]);

  const handleChangeValues = useCallback(items => {
    const newValues = {...values, ...items};
    setValues(newValues);

    const {isValid, errors} = validateForm(newValues);
    setValid(isValid);
    setErrors(errors);
  }, [values]);

  const validateForm = useCallback(values => {
    const errors = validations
      .map(validation => validation(values)) //validation
      .filter(validation => typeof validation === 'object'); //객체(오류메시지)만 필터링

    return {
      isValid: errors.length === 0,
      errors: errors.reduce((errors, error) => ({...errors, ...error}), {})
    };
  }, [validations]);

  const resetForm = useCallback(initialState => {
    !!initialState && setValues(initialState);
    setValid(false);
    setErrors({});
    setTouched({});
  }, []);

  return {
    values,
    setValues,
    errors,
    touched,
    handleChange,
    handleChangeValues,
    validateForm,
    resetForm,
    isValid,
    setValid
  };
};