import { AttachFile, MoreVert, Refresh } from "@mui/icons-material";
import { Button, Card, CardContent, CardHeader, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import AirdomIconImage from "./AirdomeIconImage";
import { useEffect, useState } from "react";
import { useUtils } from "../contexts/UtilContext";
import { useSnackbar } from "../contexts/SnackbarContext";
import { blue, cyan, grey, indigo, lime, purple, red, teal } from "@mui/material/colors";
import Led from "./Led";
import { useDialogs } from "../contexts/DialogContext";
import { useInterval } from "../contexts/useInterval";
import PropLabel from "./device/PropLabel";
import PropValue from "./device/PropValue";
import { useCode } from "../contexts/CodeContext";

const NodeAirdome = (props) => {
  const node = props.node;
  const { codes } = useCode();
  const { openDialog, getDialog } = useDialogs();
  const { api, formatNumber, formatWeather } = useUtils();
  const { showMessage } = useSnackbar();

  const [dvcList, setDvcList] = useState([]);
  const [arkr, setArkr] = useState({});
  const [awss, setAwss] = useState({});
  const [wthr, setWthr] = useState({});
  const [arss, setArss] = useState({});
  const [ardm, setArdm] = useState({});
  const [ldlt, setLdlt] = useState({});
  const [chgr, setChgr] = useState({});
  const [htbc, setHtbc] = useState({});
  const [uvld, setUvld] = useState({});
  const [fdld, setFdld] = useState({});

  const handleRead = () => {
    api.post(`/api/devices`, {
      nodeId: node.nodeId
    })
    .then(response => {
      const dvcs = response.data?.data;
      if (!!dvcs) {
        setDvcList(dvcs.sort((a, b) => a.ordNo - b.ordNo));
      }
    }).catch((e) => {
      showMessage(`[${e.response?.status}] ${e.message}`, 'error')
    });
  };
  
  useEffect(() => {
    if (!!node.nodeId) {
      handleRead();
    };

  }, []);

  useEffect(() => {
    if (dvcList) {
      setArkr(dvcList.find(dvc => dvc.dvcTypeCd === 'ARKR') || {});
      setAwss(dvcList.find(dvc => dvc.dvcTypeCd === 'AWSS') || {});
      setWthr(dvcList.find(dvc => dvc.dvcTypeCd === 'WTHR') || {});
      setArss(dvcList.find(dvc => dvc.dvcTypeCd === 'ARSS') || {});
      setArdm(dvcList.find(dvc => dvc.dvcTypeCd === 'ARDM') || {});
      setLdlt(dvcList.find(dvc => dvc.dvcTypeCd === 'LDLT') || {});
      setChgr(dvcList.find(dvc => dvc.dvcTypeCd === 'CHGR') || {});
      setHtbc(dvcList.find(dvc => dvc.dvcTypeCd === 'HTBC') || {});
      setUvld(dvcList.find(dvc => dvc.dvcTypeCd === 'UVLD') || {});
      setFdld(dvcList.find(dvc => dvc.dvcTypeCd === 'FDLD') || {});

      const airdome = getDialog('airdome');
      if (!!airdome && airdome.open && airdome.node?.nodeId === node.nodeId ) {
        openDialog('airdome', {
          node: node,
          dvcs: dvcList
        })
      }
    }
  }, [dvcList])

  const handleRefresh = (dvc) => {
    api.get(`/api/nodes/${node.nodeId}/refresh`, {})
    .then(response => {
    }).catch((e) => {
      showMessage(`[${e.response?.status}] ${e.message}`, 'error');
    });

    dvcList.forEach(dvc => {
      api.get(`/api/devices/${dvc.dvcId}/refresh`, {})
      .then(response => {
      }).catch((e) => {
        showMessage(`[${e.response?.status}] ${e.message}`, 'error');
      });
    });
  };

  const handleNode = (node) => {
    openDialog('airdome', {
      node: node,
      dvcs: dvcList
    })
  };

  const handleAttachFiles = (node) => {
    openDialog('attachFiles', {
      title: '원격디스플레이장치에 표시되는 사진을 관리할 수 있습니다.',
      node: node
    })
  }

  const displayArdmState = (ardm) => {
    let state = "-";
    if (!!ardm) {
      if (!!ardm.data?.chkOprt) {
        state = '점검운전중';
      } else if (!!ardm.data?.rstrOprt) {
        state = '정전복귀중';
      } else if (!!ardm.data?.oprt) {
        state = (codes.ArdmOprtMode?.find(item => item.code === ardm.data?.oprtMode)?.label || '') +  ' 운전중';
      } else {
        state = '정지중';
      }
    }
    return state;
  }

  useInterval(() => {
    if(node) {
      handleRead();
    }
  }, 5 * 1000);

  return (
    <Grid item size={12}>
      <Card>
        <CardHeader
          title={node.nodeId}
          titleTypographyProps={{variant: 'subtitle3', display: 'flex', mx: 1}}
          subheader={node.nodeNm}
          subheaderTypographyProps={{variant: 'subtitle1', fontWeight: 500, display: 'flex', color: 'inherit'}}
          action={<>
              <Button variant="text" size="small" startIcon={<AttachFile size="small" sx={{mr: -1}}/>} onClick={() => handleAttachFiles(node)} sx={{color: !!node.data?.connected ? '#fff' : 'grey.700'}}>사진파일</Button>
              <Button variant="text" size="small" startIcon={<Refresh size="small" sx={{mr: -1}}/>} onClick={() => handleRefresh(node)} sx={{color: !!node.data?.connected ? '#fff' : 'grey.700'}}>새로고침</Button>
              <Button variant="text" size="small" startIcon={<MoreVert size="small" sx={{mr: -1}}/>} onClick={() => handleNode(node)} sx={{color: !!node.data?.connected ? '#fff' : 'grey.700', minWidth: 'unset'}}>상세</Button>
          </>}
          sx={{px:1, py: .5, color: !!node.data?.connected ? '#fff' : 'grey.700', bgcolor: !!node.data?.connected ? 'primary.main' : 'grey.300', '& .MuiCardHeader-action': {alignSelf: 'center', margin: 0}, '& .MuiCardHeader-content': {display: 'flex', alignItems: 'center'}}}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item size={2.5} container justifyContent="center" alignItems="center">
              <AirdomIconImage atchId={node.iconAtchId} oprt={ardm.data?.oprt} />
            </Grid>
            <Grid item size={2} container direction="column" justifyContent="space-evenly" spacing={1}>
              <Grid item container alignItems="center">
                <PropLabel>IP</PropLabel>
                <PropValue sx={{ml: 2}}>{node.data?.ipAddr || '-'}</PropValue>
              </Grid>
              <Grid item container alignItems="center">
                <PropLabel>외기온도</PropLabel>
                <PropValue unit="℃" sx={{ml: 2}}>{ardm.data?.otdrTemp || '-'}</PropValue>
              </Grid>
              <Grid item container alignItems="center">
                <PropLabel>내부온도</PropLabel>
                <PropValue unit="℃" sx={{ml: 2}}>{ardm.data?.raTemp || '-'}</PropValue>
              </Grid>
              <Grid item container alignItems="center">
                <PropLabel>상태</PropLabel>
                <PropValue sx={{ml: 2}}>{displayArdmState(ardm)}</PropValue>
              </Grid>
            </Grid>
            <Grid item size={5} container spacing={2}>
              <Grid item size={12}>
                <Paper elevation={0} sx={{p: 1, bgcolor: indigo[50]}}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item size={2} sx={{textAlign: 'center'}}>외부</Grid>
                    <Grid item size={2.5} sx={{textAlign: 'center'}}>
                      <PropLabel>미세먼지</PropLabel>
                      <PropValue unit="㎍/㎥" variant="h6">{formatNumber(arkr.data?.pm10) || '-'}</PropValue>
                    </Grid>
                    <Grid item size={2.5} sx={{textAlign: 'center'}}>
                      <PropLabel>초미세먼지</PropLabel>
                      <PropValue unit="㎍/㎥" variant="h6">{formatNumber(arkr.data?.pm25) || '-'}</PropValue>
                    </Grid>
                    <Grid item size={2.5} sx={{textAlign: 'center'}}>
                      <PropLabel>온도</PropLabel>
                      <PropValue unit="℃" variant="h6">{formatNumber(awss.data?.ta, {minimumFractionDigits: 1, maximumFractionDigits: 1}) || '-'}</PropValue>
                    </Grid>
                    <Grid item size={2.5} sx={{textAlign: 'center'}}>
                      <PropLabel>습도</PropLabel>
                      <PropValue unit="%" variant="h6">{formatNumber(awss.data?.hm, {minimumFractionDigits: 1, maximumFractionDigits: 1}) || '-'}</PropValue>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item size={12}>
                <Paper elevation={0} sx={{p: 1, bgcolor: indigo[50]}}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item size={2} sx={{textAlign: 'center'}}>내부</Grid>
                    <Grid item size={2.5} sx={{textAlign: 'center'}}>
                      <PropLabel>미세먼지</PropLabel>
                      <PropValue unit="㎍/㎥" variant="h6">{formatNumber(arss.data?.fineDust) || '-'}</PropValue>
                    </Grid>
                    <Grid item size={2.5} sx={{textAlign: 'center'}}>
                      <PropLabel>초미세먼지</PropLabel>
                      <PropValue unit="㎍/㎥" variant="h6">{formatNumber(arss.data?.ufineDust) || '-'}</PropValue>
                    </Grid>
                    <Grid item size={2.5} sx={{textAlign: 'center'}}>
                      <PropLabel>온도</PropLabel>
                      <PropValue unit="℃" variant="h6">{formatNumber(arss.data?.temp, {minimumFractionDigits: 1, maximumFractionDigits: 1}) || '-'}</PropValue>
                    </Grid>
                    <Grid item size={2.5} sx={{textAlign: 'center'}}>
                      <PropLabel>습도</PropLabel>
                      <PropValue unit="%" variant="h6">{formatNumber(arss.data?.hmdt, {minimumFractionDigits: 1, maximumFractionDigits: 1}) || '-'}</PropValue>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid item size={1} container spacing={2} direction="column" justifyContent="space-evenly">
              <Grid item sx={{textAlign: 'center'}}>
                <PropLabel>날씨</PropLabel>
                <PropValue variant="h6">{formatWeather(wthr.data?.sky, wthr.data?.pty) || '-'}</PropValue>
              </Grid>
              <Grid item sx={{textAlign: 'center'}}>
                <PropLabel>풍속</PropLabel>
                <PropValue unit="m/s" variant="h6">{formatNumber(awss.data?.ws10, {minimumFractionDigits: 1, maximumFractionDigits: 1}) || '-'}</PropValue>
              </Grid>
            </Grid>
            <Grid item size={1.5} container spacing={1} direction="column" justifyContent="space-evenly">
              <Grid item>
                <Paper elevation={0} sx={{px: 1, py: 0, borderRadius: 20, bgcolor: indigo[50]}}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <PropLabel variant="subtitle1">LED조명</PropLabel>
                    <Led value={ldlt.data?.pwr} blink />
                  </Grid>
                </Paper>
              </Grid>
              <Grid item>
                <Paper elevation={0} sx={{px: 1, py: 0, borderRadius: 20, bgcolor: indigo[50]}}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <PropLabel variant="subtitle1">충전기</PropLabel>
                    <Led value={chgr.data?.pwr} blink />
                  </Grid>
                </Paper>
              </Grid>
              <Grid item>
                <Paper elevation={0} sx={{px: 1, py: 0, borderRadius: 20, bgcolor: indigo[50]}}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <PropLabel variant="subtitle1">온열벤치</PropLabel>
                    <Led value={htbc.data?.pwr} blink />
                  </Grid>
                </Paper>
              </Grid>
              <Grid item>
                <Paper elevation={0} sx={{px: 1, py: 0, borderRadius: 20, bgcolor: indigo[50]}}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <PropLabel variant="subtitle1">UVC LED</PropLabel>
                    <Led value={uvld.data?.pwr} blink />
                  </Grid>
                </Paper>
              </Grid>

            </Grid>

          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
};

export default NodeAirdome;