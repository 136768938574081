import { useState, useEffect } from "react";
import { useSnackbar } from "../contexts/SnackbarContext";
import { useUtils } from "../contexts/UtilContext";
import { useCode } from "../contexts/CodeContext";
import CompactTextField from "../components/CompactTextField";
import { isEmpty, isNotEmpty } from "../utils/validations";

import { Button, CircularProgress, Dialog, DialogActions, Grid, IconButton, InputAdornment, MenuItem, Switch, Tooltip } from "@mui/material";
import { Clear, Search } from "@mui/icons-material";
import CompactFormControlLabel from "../components/CompactFormControlLabel";
import { DatePicker } from "@mui/x-date-pickers";
import { useForm } from "../hooks/useForm";
import DraggableHeader from "../components/DraggableHeader";
import dayjs from "dayjs";
import { useDialogs } from "../contexts/DialogContext";
import AtchFile from "../components/AtchFile";
import PodoDialogTitle from "../components/PodoDialogTitle";
import PodoDialogContent from "../components/PodoDialogContent";

const AdminNode = (props) => {
  const { showMessage } = useSnackbar();
  const { api, confirmMessage} = useUtils();
  const { openDialog } = useDialogs();
  const { codes } = useCode();
  
  const mode = isNotEmpty(props.nodeId) ? 'U' : 'C';

  const initialValues = {
    nodeTypeCd: 'ADM',
    nodeSubTypeCd: '',
    grpCd: '',
    nodeId: '',
    nodeNm: '',
    modelNo: '',
    instlAddr: '',
    instlCmpnyNm: '',
    instlLattd: '',
    instlLngtd: '',
    instlYmd: null,
    ownrId: '',
    ownrNm: '',
    chrgrNm: '',
    chrgrCaddr: '',
    ordNo: '',
    useYn: true,
    iconAtchId: '',
    imgAtchId: '',
  };

  const { values, setValues, errors, touched, handleChange, handleChangeValues, resetForm, isValid } = useForm(
    initialValues,
    [
      ({nodeTypeCd}) => isNotEmpty(nodeTypeCd) || {nodeTypeCd: '시설물TYPE을 선택해주세요.'},
      ({nodeId}) => isNotEmpty(nodeId) || {nodeId: '시설물ID를 입력해주세요.'},
      ({nodeNm}) => isNotEmpty(nodeNm) || {nodeNm: '시설물명을 입력해주세요.'},
      ({instlYmd}) => !instlYmd || Date.parse(instlYmd) || {instlYmd: '잘못된 날짜값입니다.'},
      ({instlLattd}) => !isNaN(instlLattd) || {instlLattd: '잘못된 숫자값입니다.'},
      ({instlLngtd}) => !isNaN(instlLngtd) || {instlLngtd: '잘못된 숫자값입니다.'},
      ({ordNo}) => isEmpty(ordNo) || !isNaN(ordNo) || {ordNo: '잘못된 숫자값입니다.'},
    ]
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.open && mode === 'U') {
      api.get(`/api/admin/nodes/${props.nodeId}`, {})
        .then(response => {
          let nodeInfo = response.data?.data;
          if (nodeInfo != null) {
            nodeInfo.instlYmd = !!nodeInfo.instlYmd ? dayjs(nodeInfo.instlYmd) : null;
            setValues({...values, ...nodeInfo});
          }
          
        })
        .catch(e => {
          if (e.response?.status === 401) props.handleClose();
          showMessage(`[${e.response?.status}] ${e.message}`, 'error')
        });
    } else {
      resetForm(initialValues);
    }
  }, [props.open]);

  /* 저장 */
  const handleSave = () => {
    let data = Object.assign({}, values);
    data.instlYmd = dayjs.isDayjs(data.instlYmd) ? data.instlYmd.format('YYYYMMDD') : '';

    //수정
    if (mode === 'U') {
      confirmMessage('변경하시겠습니까?', () => {
        setIsLoading(true);
        api.post(`/api/admin/nodes/${props.nodeId}/update`, data)
          .then(response => {
            showMessage('변경되었습니다.');
            props.handleClose(true);
            setIsLoading(false);
          })
          .catch((e) => {
            if (e.response?.status === 401) props.handleClose();
            showMessage(`[${e.response?.status}] ${e.message}`, 'error');
            setIsLoading(false);
          });
      });
    
    } else {
      confirmMessage('시설물을 등록하시겠습니까?', () => {
        setIsLoading(true);
        api.post(`/api/admin/nodes/create`, data)
          .then(response => {
            showMessage('등록되었습니다.');
            props.handleClose(true);
            setIsLoading(false);
          })
          .catch((e) => {
            if (e.response?.status === 401) props.handleClose();
            showMessage(`[${e.response?.status}] ${e.message}`, 'error');
            setIsLoading(false);
          });
      });
    }
  };

  const handleOwnerClear = () => {
    handleChangeValues({ownrId: '', ownrNm: ''})
  };

  const handleOwnerSearch = () => {
    openDialog('adminUserSearch', {
      callback: (user) => {
        handleChangeValues({ownrId: user?.userId, ownrNm: user?.userNm});
      }
    });
  };

  const handleAttachFiles = (node) => {
    openDialog('attachFiles', {
      title: '홍보용 이미지파일',
      node: node
    })
  }

  return (
    <Dialog open={props.open} PaperComponent={DraggableHeader}>
      <PodoDialogTitle text={props.title} onClose={() => props.handleClose()} />
      <PodoDialogContent>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={4}>
            <CompactTextField name='nodeTypeCd' label="시설물TYPE"
              select
              value={values.nodeTypeCd}
              onChange={handleChange}
              size='small'
              sx={{}}
              error={touched.nodeTypeCd && !!errors.nodeTypeCd}
              helperText={(touched.nodeTypeCd && errors.nodeTypeCd) || ' '}
              FormHelperTextProps={{sx: {minHeight: '1.5em'}}}
              inputProps={{ maxLength: 30 }}
              margin="dense"
              required
              fullWidth
              disabled={mode === 'U'}
              autoComplete="off" >
              { codes.NodeType?.map((code, index) => <MenuItem key={index} value={code.code} disabled={!code.active} dense>{code.label}</MenuItem>)}
            </CompactTextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <CompactTextField name='nodeSubTypeCd' label="시설물서브TYPE"
              select
              value={values.nodeSubTypeCd}
              onChange={handleChange}
              size='small'
              sx={{}}
              error={touched.nodeSubTypeCd && !!errors.nodeSubTypeCd}
              helperText={(touched.nodeSubTypeCd && errors.nodeSubTypeCd) || ' '}
              FormHelperTextProps={{sx: {minHeight: '1.5em'}}}
              inputProps={{ maxLength: 30 }}
              margin="dense"
              fullWidth
              autoComplete="off" >
              { codes.NodeSubType?.filter(code => code.parent === values.nodeTypeCd)?.map((code, index) => <MenuItem key={index} value={code.code} disabled={!code.active} dense>{code.label}</MenuItem>)}
            </CompactTextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <CompactTextField name="grpCd" label="그룹코드"
              value={values.grpCd}
              onChange={handleChange}
              size='small'
              sx={{}}
              error={touched.grpCd && !!errors.grpCd}
              helperText={(touched.grpCd && errors.grpCd) || ' '}
              FormHelperTextProps={{sx: {minHeight: '1.5em'}}}
              inputProps={{ maxLength: 100 }}
              margin="dense"
              fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CompactTextField name='nodeId' label="시설물ID"
              value={values.nodeId}
              onChange={handleChange}
              size='small'
              sx={{}}
              error={touched.nodeId && !!errors.nodeId}
              helperText={(touched.nodeId && errors.nodeId) || ' '}
              FormHelperTextProps={{sx: {minHeight: '1.5em'}}}
              inputProps={{ maxLength: 10 }}
              margin="dense"
              required
              fullWidth
              disabled={mode === 'U'}
              autoComplete="off" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CompactTextField name="nodeNm" label="시설물명"
              value={values.nodeNm}
              onChange={handleChange}
              size='small'
              sx={{}}
              error={touched.nodeNm && !!errors.nodeNm}
              helperText={(touched.nodeNm && errors.nodeNm) || ' '}
              FormHelperTextProps={{sx: {minHeight: '1.5em'}}}
              inputProps={{ maxLength: 30 }}
              margin="dense"
              required
              fullWidth
              autoComplete="off" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CompactTextField name="modelNo" label="모델번호"
              value={values.modelNo}
              onChange={handleChange}
              size='small'
              sx={{}}
              error={touched.modelNo && !!errors.modelNo}
              helperText={(touched.modelNo && errors.modelNo) || ' '}
              FormHelperTextProps={{sx: {minHeight: '1.5em'}}}
              inputProps={{ maxLength: 100 }}
              margin="dense"
              fullWidth />
          </Grid>
          <Grid item xs={12} sm={8}>
            <CompactTextField name="instlAddr" label="설치위치"
              value={values.instlAddr}
              onChange={handleChange}
              size='small'
              sx={{}}
              error={touched.instlAddr && !!errors.instlAddr}
              helperText={(touched.instlAddr && errors.instlAddr) || ' '}
              FormHelperTextProps={{sx: {minHeight: '1.5em'}}}
              inputProps={{ maxLength: 100 }}
              margin="dense"
              fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CompactTextField name="instlCmpnyNm" label="설치업체명"
              value={values.instlCmpnyNm}
              onChange={handleChange}
              size='small'
              sx={{}}
              error={touched.instlCmpnyNm && !!errors.instlCmpnyNm}
              helperText={(touched.instlCmpnyNm && errors.instlCmpnyNm) || ' '}
              FormHelperTextProps={{sx: {minHeight: '1.5em'}}}
              inputProps={{ maxLength: 100 }}
              margin="dense"
              fullWidth/>
          </Grid>
          <Grid item xs={12} sm={4}>
            <CompactTextField name="instlLattd" label="설치위도"
              value={values.instlLattd}
              onChange={handleChange}
              size='small'
              sx={{}}
              error={touched.instlLattd && !!errors.instlLattd}
              helperText={(touched.instlLattd && errors.instlLattd) || ' '}
              FormHelperTextProps={{sx: {minHeight: '1.5em'}}}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*", maxLength: 12 }}
              margin="dense"
              fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CompactTextField name="instlLngtd" label="설치경도"
              value={values.instlLngtd}
              onChange={handleChange}
              size='small'
              sx={{}}
              error={touched.instlLngtd && !!errors.instlLngtd}
              helperText={(touched.instlLngtd && errors.instlLngtd) || ' '}
              FormHelperTextProps={{sx: {minHeight: '1.5em'}}}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*", maxLength: 12 }}
              margin="dense"
              fullWidth/>
          </Grid>
          <Grid item xs={12} sm={4}>
            <DatePicker name="instlYmd" label="설치일자"
              value={values.instlYmd}
              format="YYYY-MM-DD" onChange={value => handleChange({target: {name: 'instlYmd', value: value }})}
              slots={{ textField: CompactTextField }}
              slotProps={{ textField: {
                size: 'small',
                margin: 'dense',
                fullWidth: true,
                error: touched.instlYmd && !! errors.instlYmd,
                helperText: (touched.instlYmd && errors.instlYmd) || ' ',
                FormHelperTextProps: {sx: {minHeight: '1.5em'}}
              }, calendarHeader: {
                format: 'YYYY년 MM월'
              }}} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CompactTextField name="ownrNm" label="소유자"
              value={values.ownrNm}
              size='small'
              sx={{}}
              error={touched.ownrNm && !!errors.ownrNm}
              helperText={(touched.ownrNm && errors.ownrNm) || ' '}
              FormHelperTextProps={{sx: {minHeight: '1.5em'}}}
              InputProps={{ readOnly: true, endAdornment:
                <InputAdornment position="end" sx={{}}>
                  <Tooltip title="삭제">
                    <IconButton edge="end" onClick={handleOwnerClear}>
                      <Clear fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="검색">
                    <IconButton edge="end" onClick={handleOwnerSearch}>
                      <Search fontSize="small"/>
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              }}
              margin="dense"
              fullWidth
              disabled={mode === 'U'}
              autoComplete="off" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CompactTextField name="chrgrNm" label="담당자명"
              value={values.chrgrNm}
              onChange={handleChange}
              size='small'
              sx={{}}
              error={touched.chrgrNm && !!errors.chrgrNm}
              helperText={(touched.chrgrNm && errors.chrgrNm) || ' '}
              FormHelperTextProps={{sx: {minHeight: '1.5em'}}}
              inputProps={{ maxLength: 30 }}
              margin="dense"
              fullWidth/>
          </Grid>
          <Grid item xs={12} sm={4}>
            <CompactTextField name="chrgrCaddr" label="담당자연락처"
              value={values.chrgrCaddr}
              onChange={handleChange}
              size='small'
              sx={{}}
              error={touched.chrgrCaddr && !!errors.chrgrCaddr}
              helperText={(touched.chrgrCaddr && errors.chrgrCaddr) || ' '}
              FormHelperTextProps={{sx: {minHeight: '1.5em'}}}
              inputProps={{ maxLength: 30 }}
              margin="dense"
              fullWidth/>
          </Grid>
          <Grid item xs={12} sm={4}>
            <CompactTextField name="ordNo" label="정렬순서"
              value={values.ordNo}
              onChange={handleChange}
              size='small'
              sx={{}}
              error={touched.ordNo && !!errors.ordNo}
              helperText={(touched.ordNo && errors.ordNo) || ' '}
              FormHelperTextProps={{sx: {minHeight: '1.5em'}}}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*", maxLength: 5 }}
              margin="dense"
              fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CompactFormControlLabel
              control={<Switch name="useYn" checked={!!values.useYn} onChange={handleChange} />}
              label="사용여부" sx={{mt: 0.5}} />
          </Grid>
          {
            mode === 'U' && values.nodeTypeCd === 'ADM' && 
          <Grid item xs={12} sm={4}>
            <Button variant="contained" color="info" size="small" onClick={() => handleAttachFiles(values)} sx={{mt: 0.5}}>
              홍보이미지파일관리
            </Button>
          </Grid>
          }
        </Grid>
        
        <AtchFile atchId={values.iconAtchId} title="대시보드 아이콘" onAtchIdChange={atchId => handleChangeValues({iconAtchId: atchId})} accept="image/*" size={1} />

      </PodoDialogContent>

      <DialogActions sx={{pr: 3, pb: 2}}>
        <Button variant="contained" disabled={!isValid || isLoading} onClick={handleSave}>
        {isLoading ? <CircularProgress size={24} color="inherit" /> : '저장'}
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default AdminNode;