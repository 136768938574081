import { Box, Button, IconButton, Link, List, ListItem, Paper, styled, Tooltip, Typography } from "@mui/material";
import { useSnackbar } from "../contexts/SnackbarContext";
import { useUtils } from "../contexts/UtilContext";
import { grey } from "@mui/material/colors";
import { Add, Clear } from "@mui/icons-material";
import { useEffect, useState } from "react";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const AtchFile = (props) => {
  const { showMessage } = useSnackbar();
  const { api, confirmMessage } = useUtils();
  const atchId = props.atchId;
  const onAtchIdChange = props.onAtchIdChange;
  const onAddFile = props.onAddFile;
  const onDeleteFile = props.onDeleteFile;
  const size = props.size;
  const accept = props.accept || '*/*';

  const [fileList, setFileList] = useState([]);
  const full = !isNaN(size) && fileList.length >= size;

  const handleRead = () => {
    api.post(`/api/files`, {
      atchId: atchId
    })
    .then(response => {
      const list = response.data?.data;
      setFileList(list);
    })
    .catch((e) => {
      showMessage(`[${e.response?.status}] ${e.message}`, 'error')
    });
  }

  const handleUploadFile = (e, atchId) => {
    if (e.target.files.length) {
      const file = e.target.files[0];

      //파일유형 검사
      if (!["image/apng", "image/bmp", "image/gif", "image/jpeg", "image/pjpeg", "image/png", "image/svg+xml", "image/tiff", "image/webp", "image/x-icon"].includes(file.type)) {
        showMessage(`이미지만 업로드할 수 있습니다.`, 'warning');
        e.target.value = '';
        return;
      }

      let formData = new FormData();
      formData.append("file", file);
      formData.append("atchId", atchId || '');

      api.post(`/api/files/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      })
      .then(response => {
        if (onAtchIdChange && response.data?.atchId !== atchId) {
          onAtchIdChange(response.data?.atchId)          
        }
        if (onAddFile) {
          onAddFile(response.data?.atchFileId)
        }
        handleRead();
      })
      .catch (e => {
        showMessage(`[${e.response?.status}] ${e.message}`, 'error')
      });

      e.target.value = '';
    }
  }

  const handleDowloadFile = (atchFileId) => {
    api.get(`/api/files/download/${atchFileId}`, {
      responseType: 'blob'  // Blob 형식으로 응답을 받음
    })
    .then(response => {
      // 응답으로 받은 Blob 데이터를 사용해 파일을 생성
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const disposition = response.headers['content-disposition'];

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.style.display = 'none';
      link.setAttribute('download', decodeURI(disposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1].replace(/['"]/g, ""))); // 파일 이름 설정
      document.body.appendChild(link);
      link.click();
      link.remove();

      window.URL.revokeObjectURL(downloadUrl);
    })
    .catch (e => {
      showMessage(`[${e.response?.status}] ${e.message}`, 'error')
    });
  }

  const handleDeleteFile = (atchFileId) => {
    confirmMessage('파일을 삭제하시겠습니까?',
      () => {
        api.delete(`/api/files/delete/${atchFileId}`)
          .then(response => {
            handleRead();
            if (onDeleteFile) {
              onDeleteFile(response.data?.atchFileId);
            }
          })
          .catch((e) => {
            showMessage(`[${e.response?.status}] ${e.message}`, 'error')
          });
      }
    );
  }

  useEffect(() => {
    if (!!atchId) {
      handleRead();
    } else {
      setFileList([]);
    }
  }, [atchId]);

  return (
    <Paper elevation={0} sx={{bgcolor: grey[100], p: 1, mb: 2}}>
      <Box display='flex' justifyContent='flex-end' alignItems='center'>
        <Typography variant="subtitle2" component="div" flexGrow={1}>{ props.title }</Typography>
        {!full && 
        <Button component="label" variant="text" size="small" startIcon={<Add sx={{mr: -.5}}/>}>
          추가
          <VisuallyHiddenInput type="file" onChange={e => handleUploadFile(e, atchId)} accept={accept} />
        </Button>}
      </Box>
      <Box sx={{minHeight: '1.35em', maxHeight: '4em', overflow: 'auto'}}>
        <List sx={{py: 0}}>
          { fileList?.map((atchFile, index) => (
            <ListItem key={index} sx={{py: 0}}>
              <Link variant="body2" sx={{cursor: "pointer"}} onClick={() => handleDowloadFile(atchFile.atchFileId)}>{atchFile.fileNm}</Link>
              <Tooltip title="삭제">
                <IconButton size="small" edge="end" aria-label="delete file" onClick={() => handleDeleteFile(atchFile.atchFileId)}>
                  <Clear fontSize="small" />
                </IconButton>
              </Tooltip>
            </ListItem>
          )) }
        </List>
      </Box>
  </Paper>
  );
};

export default AtchFile;