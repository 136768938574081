import { Box, Dialog, DialogContent, Link } from "@mui/material";
import DraggableHeader from "../components/DraggableHeader";
import AdminDataGrid from "../components/AdminDataGrid";
import { useEffect, useState } from "react";
import { useUtils } from "../contexts/UtilContext";
import { useSnackbar } from "../contexts/SnackbarContext";
import PodoDialogTitle from "../components/PodoDialogTitle";
import { useForm } from "../hooks/useForm";
import CompactTextField from "../components/CompactTextField";
import HelpTooltip from "../components/HelpTooltip";

const AwsStnSearch = (props) => {
  const { showMessage } = useSnackbar();
  const { api, formatNumber } = useUtils();
  const [ rows, setRows ] = useState([]);
  const node = props.node;

  const { values, handleChange, resetForm, isValid } = useForm({
    stnNm: ''
  }, []);
  
  const handleRead = () => {
    api.post('/api/common/awsStns', values)
      .then(response => {
        const list = response.data?.data;
        if (Array.isArray(list)) {
          list.forEach(stn => {
            stn.dist = calculateDistance(stn);
          });

          setRows(list.sort((a, b) => a.dist - b.dist));
        }
        
      })
      .catch(e => {
        showMessage(`[${e.response?.status}] ${e.message}`, 'error')
      });
  };

  const handleSelect = (data) => {
    props.handleClose(data);
  };

  const radians = (degree) => {
    return Math.PI / 180 * degree
  }

  const calculateDistance = (data) => {
    if (!isNaN(node?.instlLattd) && !isNaN(node?.instlLngtd)) {
      return 6371 * Math.acos(Math.cos(radians(90 - node?.instlLattd)) * Math.cos(radians(90 - data.lat)) + Math.sin(radians(90 -node?.instlLattd)) * Math.sin(radians(90 - data.lat)) * Math.cos(radians(node?.instlLngtd - data.lon)));
    } else {
      return null;
    }
  }

  useEffect(() => {
    if (props.open) {
      handleRead();
    } else {
      resetForm({
        stnNm: ''
      })
    }
  }, [props.open]);

  const columns = [
    { field: 'stnId', headerName: '측정소번호', type: 'string', align: 'center', flex: 2},
    { field: 'stnKo',  headerName: '측정소명', type: 'string', align: 'center', flex: 3, renderCell: data => (<Link component="button" onClick={() => handleSelect(data.row)}>{data.row.stnKo}</Link>)},
    { field: 'dist',  headerName: '거리(km)', type: 'number', align: 'center', flex: 3, valueFormatter: value => formatNumber(value, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + 'km'},
  ]

  return (
    <Dialog open={props.open} PaperComponent={DraggableHeader}>
      <PodoDialogTitle text="AWS측정소 검색" onClose={() => props.handleClose()}/>
      <DialogContent sx={{mt: 2, pb: 0}}>
        <Box display='flex' alignItems="center">
          <CompactTextField name="stnNm" label="명칭검색"
              value={values.stnNm}
              onChange={handleChange}
              onKeyDown={e => e.key === 'Enter' && handleRead()}
              size='small'
              sx={{ width: 200}}
              inputProps={{ maxLength: 10 }}
              margin="dense"
               />
          <HelpTooltip>검색어 입력 후 Enter 하여 검색합니다.</HelpTooltip>
        </Box>
        <Box sx={{ height: '50vh', mb: 2}}>
          <AdminDataGrid
            rows={rows}
            columns={columns}
            getRowId={row => row.stnId}
            density="compact"
            disableColumnMenu
            
          />
      </Box>
      </DialogContent>
    </Dialog>
  )
};

export default AwsStnSearch;