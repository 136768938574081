import { DatePicker, renderTimeViewClock, TimePicker } from "@mui/x-date-pickers";
import CompactTextField from "../CompactTextField";

const PropDatePicker = ( props ) => {
  return (
    <DatePicker name={props.name} label={props.label}
      value={props.value}
      format="MM-DD"
      onChange={value => props.onChange({target: {name: props.name, value: value }})}
      slots={{ textField: CompactTextField }}
      slotProps={{ textField: {
        size: 'small',
        margin: 'dense',
        FormHelperTextProps: {sx: {minHeight: '1.5em'}},
        sx: {maxWidth: 125}
      }, calendarHeader: {
        format: 'YYYY년 MM월'
      }}}
      disabled={props.disabled || false} />
  )
};

export default PropDatePicker;