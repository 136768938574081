import dayjs from "dayjs";

export const isEmpty = (value) => (value === null || value === undefined || value === "");

export const isNotEmpty = (value) => !isEmpty(value);

export const isEmail = (value) => /\S+@\S+\.\S+/.test(value);

export const isNaD = (value) => {
  return !(value instanceof Date || dayjs.isDayjs(value)) || isNaN(value);
}