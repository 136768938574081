import { useEffect, useState } from "react";
import { useUtils } from "../contexts/UtilContext";
import { useDialogs } from "../contexts/DialogContext";
import { useSnackbar } from "../contexts/SnackbarContext";
import PodoDialogTitle from "../components/PodoDialogTitle";
import PodoDialogContent from "../components/PodoDialogContent";
import { Box, Button, Dialog, Link } from "@mui/material";
import DraggableHeader from "../components/DraggableHeader";
import { Add, Delete } from "@mui/icons-material";
import AdminDataGrid from "../components/AdminDataGrid";

const AdminDevices = (props) => {
  const { api, confirmMessage } = useUtils();
  const { openDialog } = useDialogs();
  const { showMessage } = useSnackbar();

  const [ rows, setRows ] = useState([]);
  const [ selected, setSelected ] = useState([]);
  const [ saved, setSaved ] = useState(false);  //디바이스 저장여부
  const [ isLoading, setIsLoading ] = useState(false);

  // 노드디바이스 조회
  const handleRead = () => {
    setIsLoading(true);
    api.get(`/api/admin/nodes/${props.nodeId}/devices`)
      .then(response => {
        setRows(response.data?.data);
        setIsLoading(false);
      }).catch(e => {
        showMessage(`[${e.response?.status}] ${e.message}`, 'error');
        setIsLoading(false);
      });
  }

   // handle add
   const handleAdd = () => {
    openDialog('adminDevice', {
      title: '디바이스 등록',
      nodeId: props.nodeId,
      nodeNm: props.nodeNm,
      dvcId: '',
      callback: () => {
        handleRead();
        setSaved(true);
      }
    });
  };

  // handle update
  const handleUpdate = (data) => {
    openDialog('adminDevice', {
      title: '디바이스 수정',
      nodeId: data.nodeId,
      nodeNm: data.nodeNm,
      dvcId: data.dvcId,
      callback: () => {
        handleRead();
        setSaved(true);
      }
    });
  }

  const handleDelete = () => {
    if (selected.length === 0) {
      showMessage(`선택된 항목이 없습니다.`, 'warning');
      return;
    }

    confirmMessage('선택된 항목을 삭제하시겠습니까?',
      () => {
        setIsLoading(true);
        api.get(`/api/admin/nodes/${props.nodeId}/devices/${selected[0]}/delete`)
          .then(response => {
            handleRead();
            setSaved(true);
            showMessage(response?.message || '삭제되었습니다.');
            setIsLoading(false);
          })
          .catch((e) => {
            showMessage(`[${e.response?.status}] ${e.message}`, 'error');
            setIsLoading(false);
          });
      }
    );
  }

  useEffect(() => {
    setSaved(false);

    if (props.open) {
      handleRead();
    } else {
      setRows([]);
    }

  }, [props.open]);

  const columns = [
    { field: 'dvcId',  headerName: '디바이스ID', type: 'string', align: 'center', flex: 4,
      renderCell: data => (<Link component="button" onClick={() => handleUpdate(data.row)}>{data.row.dvcId}</Link>)},
    { field: 'dvcTypeCd',  headerName: '디바이스타입', type: 'string', align: 'center', flex: 3},
    { field: 'dvcNm',  headerName: '디바이스명', type: 'string', align: 'center', flex: 3},
    { field: 'ordNo',  headerName: '정렬순서', type: 'number', align: 'center', flex: 2 },
    { field: 'useYn',  headerName: '사용여부', type: 'boolean', align: 'center', flex: 2},
  ]

  return (
    <Dialog open={props.open} PaperComponent={DraggableHeader} sx={{}} maxWidth={false}>
      <PodoDialogTitle text={`디바이스 [${props.nodeId}/${props.nodeNm}]`} onClose={() => props.handleClose(saved)}/>
      <PodoDialogContent>
        <Box display='flex' justifyContent='flex-end'>
          <Button variant="text" startIcon={<Add sx={{mr: -.5}}/>} onClick={handleAdd}>
            추가
          </Button>
          <Button variant="text" startIcon={<Delete sx={{mr: -.5}}/>} onClick={handleDelete}>
            삭제
          </Button>
        </Box>

        <Box sx={{minWidth: 550}}>
          <AdminDataGrid
            rows={rows}
            columns={columns}
            getRowId={row => row.dvcId}
            density="compact"
            disableColumnMenu
            autoHeight
            loading={isLoading}
            rowSelectionModel={ selected } onRowSelectionModelChange={newSelected => setSelected(newSelected)}
          />
        </Box>
      </PodoDialogContent>
    </Dialog>
  )
};

export default AdminDevices;