import { IconButton, styled } from "@mui/material";
import { grey } from "@mui/material/colors";

const DialogCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 4,
  right: 4,
  color: grey[300],
}));

export default DialogCloseButton;