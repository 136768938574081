import { Card, Grid } from "@mui/material";
import { useUtils } from "../../contexts/UtilContext";
import DeviceCardHeader from "./DeviceCardHeader";
import DeviceCardContent from "./DeviceCardContent";
import PropLabel from "./PropLabel";
import PropValue from "./PropValue";
import { useCode } from "../../contexts/CodeContext";

const Awss = (props) => {
  const { awsStns } = useCode();
  const { formatNumber } = useUtils();

  const node = props.node;
  const dvc = props.dvc;
  const data = dvc.data;

  return (
    <Grid item xs={5}>
      <Card>
        <DeviceCardHeader node={node} dvc={dvc} setup/>
        <DeviceCardContent>
          <Grid container alignItems='start' justifyContent="space-evenly">
            <Grid item xs={3} textAlign='center'>
              <PropLabel>측정소</PropLabel>
              <PropValue>{ awsStns.find(stn => stn.stnId === data.stnId)?.stnKo || '-'}</PropValue>
            </Grid>
            <Grid item xs={2} textAlign='center'>
              <PropLabel>온도</PropLabel>
              <PropValue unit="℃">{formatNumber(data.ta, {minimumFractionDigits: 1, maximumFractionDigits: 1}) || '-'}</PropValue>
            </Grid>
            <Grid item xs={2} textAlign='center'>
              <PropLabel>습도</PropLabel>
              <PropValue unit="%RH">{formatNumber(data.hm, {minimumFractionDigits: 1, maximumFractionDigits: 1}) || '-'}</PropValue>
            </Grid>
            <Grid item xs={2} textAlign='center'>
              <PropLabel>강수량</PropLabel>
              <PropValue unit="mm">{formatNumber(data.rn15m, {minimumFractionDigits: 1, maximumFractionDigits: 1}) || '-'}</PropValue>
            </Grid>
            <Grid item xs={2} textAlign='center'>
              <PropLabel>풍속</PropLabel>
              <PropValue unit="m/s">{formatNumber(data.ws10, {minimumFractionDigits: 1, maximumFractionDigits: 1}) || '-'}</PropValue>
            </Grid>
          </Grid>
        </DeviceCardContent>
      </Card>
    </Grid>
  )

}

export default Awss;