import React, { useEffect } from 'react';
import { Header } from '../components/Header';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';


const Main = () => {

  const navigator = useNavigate();
  const location = useLocation();


  useEffect(() => {
    if (location.pathname.endsWith('/main')) {
      navigator('/main/nodes');
    }
  }, [location.pathname, navigator]);

  return (
    <>
      <Header />
      <main style={{ marginTop: '48px', padding: '20px' }}>
        <Outlet/>
      </main>
    </>
  );
};

export default Main;