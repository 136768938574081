import { createContext, useContext, useMemo, useState } from "react";

import axios from 'axios';
import { useSnackbar } from 'notistack';

import { useAuth } from "./AuthContext";
import { Loading } from '../components/Loading';
import { Confirm } from "../components/Confirm";
import { Info } from "@mui/icons-material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isNotEmpty } from "../utils/validations";

const UtilContext = createContext();

export const useUtils = () => useContext(UtilContext);

export const UtilsProvider = ({children}) => {
  const [loading, setLoading] = useState(false);
  const [confirms, setConfirms] = useState({
    open: false,
    text: [],
    onNo: () => {},
    onYes: () => {}
  });

  const { enqueueSnackbar } = useSnackbar();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const errorMessage = (msg) => {
    enqueueSnackbar(msg, { variant: 'error' });
  };

  const message = (msg, variant) => {
    enqueueSnackbar(msg, { variant: variant || 'success' });
  };

  const timeString = (from, to) => {
    return (isNotEmpty(from) ? [from.substr(0,2), ':', from.substr(2)].join('') : '')
      + ' ~ '
      + (isNotEmpty(to) ? [to.substr(0,2), ':', to.substr(2)].join('') : '');
  }

  const formatNumber = (value, options) => {
    return (value === null || value === undefined || value === '') ? ''
      : new Intl.NumberFormat(navigator.language, options).format(value);
  }

  const formatWeather = (sky, pty) => {
    if (isNotEmpty(pty) && pty !== '0') {
      return {
        "0": "없음",
        "1": "비",
        "2": "비/눈",
        "3": "눈",
        "4": "소나기",
        "5": "빗방울",
        "6": "비/눈날림",
        "7": "눈날림"
      }[pty];
    } else {
      return {
        "1": "맑음",
        "3": "구름많음",
        "4": "흐림"
      }[sky]
    }
  }

  const loadingStart = () => {
    setLoading(true);
  };

  const loadingEnd = () => {
    setLoading(false);
  };

  const confirmMessage = (text, handleYes, handleNo) => {
    setConfirms({
      open: true,
      text: text,
      onYes: () => {
        setConfirms(prev => ({...prev, open: false}));
        if (handleYes) {
          handleYes();
        }
      },
      onNo: () => {
        setConfirms(prev => ({...prev, open: false}));
        if (handleNo) {
          handleNo();
        }
      }
    })
  };

  const [ isFullscreen, setIsFullscreen] = useState(false);

  const fullscreen = {
    active: isFullscreen,
    enter: () => {
      document.body.requestFullscreen();
    },
    exit: () => {
      document.exitFullscreen();
    },
    toggle: () => {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        document.body.requestFullscreen();
      } 
    }
  };

  useEffect(() => {
    const onFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    }
  
    document.addEventListener('fullscreenchange', onFullscreenChange);
    return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, []);
  

  const gridHasErrors = (grid) => {
    return grid.collectionView.items.some((item, index) => {
      return grid.columns.some((col) => {
          return !!grid.collectionView.getError(item, col.binding);
      })
    });
  };

  // axios
  const api = (() => {
    let instance = axios.create({
      timeout: 100000
    });

    instance.interceptors.request.use(
      config => {
        config.headers['Authentication'] = JSON.parse(window.sessionStorage.getItem("jwt"));
        // config.headers['X-XSRF-TOKEN'] = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, '$1');
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    instance.interceptors.response.use(
      response => {
        const data = response.data;
        const status = response.status;
        const headers = response.headers;
        const code = data["code"];
        const message = data["message"];

        //정상 응답의 에러 처리
        if (isNotEmpty(code) && code !== 'SUCCESS') {
          return Promise.reject({
            code: code,
            message: message,
            response: {
              status: code,
              headers: headers,
              data: data
            }
          });
        }
        //표준 에러 처리
        else if (status < 200 || status >= 300) {
          console.log("%%%%%%", response);
          return Promise.reject(data);
        }

        return Promise.resolve(response);
      },
      error => {
        //TODO: 보완해야 함. 특히 세션 만료시 에러메시지 처리
        if (error.response) {
          if (error.response.status) {
            if (error.response.status === 401) {
              error.message = '로그인 후 이용해주세요.';
              logout();
              navigate('/', { replace: true });
            } else {
              error.message = error.response?.statusText || error.message || '관리자에게 문의해주세요';
            }
          } else if (error.response.data) {
              const { code, message } = error.response.data;
              error.status = code || error.response.data.status || '';
              error.message = message || error.response.data.error || '관리자에게 문의해주세요';
          }

          return Promise.reject(error);

        } else {
          return Promise.reject({
            response: {
              status: ''
            }
          });
        }
      }
    );

    return instance;
  })();

  return (
    <UtilContext.Provider value={{
      timeString,
      formatNumber,
      formatWeather,
      loadingStart,
      loadingEnd,
      confirmMessage,
      gridHasErrors,
      fullscreen,
      api
    }}>
      <Loading open={loading} />
      <Confirm open={confirms.open}
        icon={<Info sx={{ color: '#aaccff', fontSize: 60}} />}
        text={confirms.text}
        onNo={confirms.onNo}
        onYes={confirms.onYes} />
      {children}
    </UtilContext.Provider>
  ) ;
};

