import { Typography } from "@mui/material";

const PropValue = (props) => {

  return (
    <Typography variant={props.variant || "h7"} color={props.color || "inherit"} sx={props.sx}>
      { props.children }
      { !!props.unit && <Typography variant="unit"> {props.unit}</Typography>}
    </Typography>
  );
};

export default PropValue;