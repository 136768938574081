import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useDialogs } from "../contexts/DialogContext";

export const ProtectedRoute = ({roles, children}) => {
  const { closeDialogAll } = useDialogs();
  const { user } = useAuth();
  const hasRole = !roles || !Array.isArray(roles) || !roles.length || roles.findIndex(role => user?.roles?.includes(role)) > -1;

  if (!user) {
    // user is not authenticated
    //TODO: 토큰이 만료되었을 때에도 로그아웃시켜야 함.
    closeDialogAll();
    return <Navigate to="/login" />;
  } else if (!hasRole) {
    return <Navigate to="/accessDenied" replace />
  }

  return children;

  // return (
  //   <Container sx={{height: '100vh'}}>
  //     <Grid container spacing={2} alignItems="flex-start" justifyContent="center">
  //       <Grid item xs={12} container>
  //         <Header />
  //       </Grid>
  //       <Grid item xs={12} container>
  //         {children}
  //       </Grid>
  //     </Grid>
  //   </Container>
  // );
};