import { Button, Dialog, Typography } from "@mui/material"
import Grid from "@mui/material/Grid2"
import DraggableHeader from "../components/DraggableHeader"
import PodoDialogTitle from "../components/PodoDialogTitle";
import { DownloadSharp, Search } from "@mui/icons-material";
import PodoDialogContent from "../components/PodoDialogContent";
import { useEffect, useState } from "react";
import { useUtils } from "../contexts/UtilContext";
import { useSnackbar } from "../contexts/SnackbarContext";
import PodoDialogActions from "../components/PodoDialogActions";

import { useAuth } from "../contexts/AuthContext";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import * as xlsx from 'xlsx';
import { isNotEmpty, isNaD } from "../utils/validations";
import CompactTextField from "../components/CompactTextField";
import { useForm } from "../hooks/useForm";
import AdminDataGrid from "../components/AdminDataGrid";
import { BarChart } from "@mui/x-charts";

const Healthpole = (props) => {
  const { user } = useAuth();
  const { api } = useUtils();
  const { showMessage } = useSnackbar();
  
  const node = props.node;
  const dvcs = props.dvcs;

  const isAdmin = Array.isArray(user?.roles) && user.roles.includes('ROLE_ADMIN');

  const now = new Date();

  const initialValues = {
    stDtm: dayjs(now).subtract(1, 'week'),
    edDtm: dayjs(now),
    nodeId: node.nodeId,
    desc: "1"
  };

  const { values, setValues, errors, touched, handleChange, isValid, setValid, validateForm } = useForm(
    initialValues,
    [
      ({stDtm}) => !isNaD(stDtm) || {stDtm: '시작일시를 올바르게 입력해주세요.'},
      ({stDtm}) => isNotEmpty(stDtm) || {stDtm: '시작일시를 입력해주세요.'},
      ({edDtm}) => !isNaD(edDtm) || {edDtm: '종료일시를 올바르게 입력해주세요.'},
      ({edDtm}) => isNotEmpty(edDtm) || {edDtm: '종료일시을 입력해주세요.'}
    ]
  );

  const [logHplList, setLogHplList] = useState([]);
  const [logHplSumHours, setLogHplSumHours] = useState([]);
  const [logHplSumWeekdays, setLogHplSumWeekdays] = useState([]); 

  const logHplColumns = [
    { field: 'dvcNm', headerName: '구분', type: 'string', align: 'center', flex: 3},
    { field: 'stDtm',  headerName: '시작시각', type: 'string', align: 'center', flex: 4},
    { field: 'edDtm',  headerName: '종료시각', type: 'string', align: 'center', flex: 4},
    { field: 'cnt',  headerName: '운동횟수', type: 'number', align: 'center', flex: 2 },
    { field: 'dur',  headerName: '운동시간(초)', type: 'number', align: 'center', flex: 2 },
    { field: 'cal',  headerName: '소모칼로리(cal)', type: 'number', align: 'center', flex: 3 },
  ]

  const [ logHplLoading, setLogHplLoading ] = useState(false);
  const [ logHplHoursLoading, setLogHplHoursLoading ] = useState(false);
  const [ logHplWeekdaysLoading, setLogHplWeekdaysLoading ] = useState(false);

  const handleRead = () => {
    const params = {...values,
      stDtm: dayjs.isDayjs(values.stDtm) ? values.stDtm.format('YYYY-MM-DD') : null,
      edDtm: dayjs.isDayjs(values.edDtm) ? values.edDtm.format('YYYY-MM-DD') : null
    };

    setLogHplLoading(true);
    api.post(`/api/logs/hpl/${node.nodeId}`, params)
      .then(response => {
        setLogHplList(response.data?.data);
        setLogHplLoading(false);
      })
      .catch(e => {
        showMessage(`[${e.response?.status}] ${e.message}`, 'error');
        setLogHplLoading(false);
      });

    setLogHplHoursLoading(true);
    api.post(`/api/logs/hpl/${node.nodeId}/hours`, params)
      .then(response => {
        const result = response.data?.data;
        setLogHplSumHours(result ? result.map(item => item.cnt) : []);
        setLogHplHoursLoading(false);
      })
      .catch(e => {
        showMessage(`[${e.response?.status}] ${e.message}`, 'error');
        setLogHplHoursLoading(false);
      });

    setLogHplWeekdaysLoading(true);
    api.post(`/api/logs/hpl/${node.nodeId}/weekdays`, params)
      .then(response => {
        const result = response.data?.data;
        setLogHplSumWeekdays(result ? result.map(item => item.cnt) : []);
        setLogHplWeekdaysLoading(false);
      })
      .catch(e => {
        showMessage(`[${e.response?.status}] ${e.message}`, 'error');
        setLogHplWeekdaysLoading(false);
      });
  };

  const handleExcel = () => {
    if (logHplList && logHplList.length) {
      const data = logHplList.map(item => ({
        "구분": item.dvcNm,
        "시작시각": item.stDtm,
        "종료시각": item.edDtm,
        "운동횟수": item.cnt,
        "운동시간(초)": item.dur,
        "소모칼로리(cal)": item.cal
      }));

      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      xlsx.writeFile(workbook, `${props.node?.nodeNm}_사용기록.xlsx`);

    } else {
      showMessage('다운로드할 데이터가 없습니다.', 'error');
    }
  }

  useEffect(() => {
    if (props.open) {
      handleChange({target: {name: "nodeId", value: props.node.nodeId }})
    } else {
      setLogHplList([]);
      setLogHplSumHours([]);
      setLogHplSumWeekdays([]);
    }
  }, [props]);


  return (
    <Dialog open={props.open} PaperComponent={DraggableHeader} aria-hidden={!props.open} maxWidth="lg">
      <PodoDialogTitle on={!!node.data?.connected} text={`[${node.nodeId}] ${node.nodeNm}: 운동기록`} onClose={() => props.handleClose()} />
      <PodoDialogContent>
        <Grid container spacing={2} sx={{minWidth: 1200}}>
          <Grid item size={12} container spacing={2} justifyContent="stretch">
            <Grid item flexGrow={1} container spacing={2}>
              <DatePicker name="stDtm" label="사용기간(시작)"
                value={values.stDtm}
                format="YYYY-MM-DD"
                onChange={value => handleChange({target: {name: "stDtm", value: value }})}
                slots={{ textField: CompactTextField }}
                slotProps={{ textField: {
                  size: 'small',
                  margin: 'dense',
                  error: touched.stDtm && !!errors.stDtm,
                  helperText: (touched.stDtm && errors.stDtm) || ' ',
                  required: true,
                  FormHelperTextProps: {sx: {minHeight: '1.5em'}},
                  sx: {maxWidth: 150}
                }, calendarHeader: {
                  format: 'YYYY년 MM월'
                }}}
                />
              <DatePicker name="edDtm" label="사용기간(종료)"
                value={values.edDtm}
                format="YYYY-MM-DD"
                onChange={value => handleChange({target: {name: "edDtm", value: value }})}
                slots={{ textField: CompactTextField }}
                slotProps={{ textField: {
                  size: 'small',
                  margin: 'dense',
                  error: touched.edDtm && !!errors.edDtm,
                  helperText: (touched.edDtm && errors.edDtm) || ' ',
                  required: true,
                  FormHelperTextProps: {sx: {minHeight: '1.5em'}},
                  sx: {maxWidth: 150}
                }, calendarHeader: {
                  format: 'YYYY년 MM월'
                }}}
                />
            </Grid>
            <Grid item flexGrow={0} container spacing={2} alignItems="center" sx={{pt: 1, mb:'1.5em'}}>
              <Button variant="contained" disabled={!isValid} startIcon={<Search/>} onClick={handleRead} size="small" >조회</Button>
              <Button variant="contained" disabled={!isValid} startIcon={<DownloadSharp />} onClick={handleExcel} size="small" >엑셀</Button>
            </Grid>
          </Grid>
          
          <Grid item size={12} sx={{height: 400}}>
            <AdminDataGrid
              rows={logHplList}
              columns={logHplColumns}
              rowHeight={40}
              getRowId={row => row.sid}
              density="compact"
              disableColumnMenu
              loading={logHplLoading}
            />
          </Grid>

          <Grid item size={8}>
            <Typography textAlign="center" variant="subtitle2" sx={{fontWeight: 700}}>시간대별 운동횟수</Typography>
            <BarChart
              slotProps={{
                legend: {
                  hidden: true
                }
              }}
              xAxis={[{
                scaleType: 'band',
                data: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
                tickPlacement: 'middle',
                label: "시간"
              }]}
              yAxis={[{
                label: '운동횟수(회)'
              }]}
              series={[{data: logHplSumHours, label: '운동횟수'}]}
              height={200}
              loading={logHplHoursLoading}
            />
          </Grid>
          <Grid item size={4}>
            <Typography textAlign="center" variant="subtitle2" sx={{fontWeight: 700}}>요일별 운동횟수</Typography>
            <BarChart
              slotProps={{
                legend: {
                  hidden: true
                }
              }}
              xAxis={[{
                scaleType: 'band',
                data: ['일', '월', '화', '수', '목', '금', '토'],
                tickPlacement: 'middle',
                label: "요일"
              }]}
              yAxis={[{
                label: '운동횟수(회)'
              }]}
              series={[{data: logHplSumWeekdays, label: '운동횟수'}]}
              height={200}
              loading={logHplWeekdaysLoading}
            />
          </Grid>
        </Grid>
      </PodoDialogContent>
      <PodoDialogActions/>
    </Dialog>
  );
};

export default Healthpole;