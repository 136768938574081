import { Typography } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const Led = (props) => {
  const on = !!props.value;
  const color = props.color || "#22E54B";
  const blink = !!props.blink;

  return <Typography variant={props.variant || "h5"} sx={{ color: on ? color : "#00000014", lineHeight: 0}}><FiberManualRecordIcon className={blink && on ? "blink" : ""}/></Typography>;
}

export default Led;