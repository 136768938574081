import { Card, Grid } from "@mui/material";

import { useUtils } from "../../contexts/UtilContext";
import DeviceCardHeader from "./DeviceCardHeader";
import DeviceCardContent from "./DeviceCardContent";
import PropLabel from "./PropLabel";
import PropValue from "./PropValue";

const Arkr = (props) => {
  const { formatNumber } = useUtils();
  const node = props.node;
  const dvc = props.dvc;
  const data = dvc.data;

  return (
    <Grid item xs={3}>
      <Card>
        <DeviceCardHeader node={node} dvc={dvc} on={true} setup />
        <DeviceCardContent>
          <Grid container alignItems='start' justifyContent="space-evenly">
            <Grid item xs={4.5} textAlign='center'>
              <PropLabel>측정소</PropLabel>
              <PropValue>{data.sttn || '-'}</PropValue>
            </Grid>
            <Grid item xs={3.75} textAlign='center'>
              <PropLabel>미세먼지</PropLabel>
              <PropValue unit="㎍/㎥">{formatNumber(data.pm10) || '-'}</PropValue>
            </Grid>
            <Grid item xs={3.75} textAlign='center'>
              <PropLabel>초미세먼지</PropLabel>
              <PropValue unit="㎍/㎥">{formatNumber(data.pm25) || '-'}</PropValue>
            </Grid>
          </Grid>
        </DeviceCardContent>
      </Card>
    </Grid>
  );

}

export default Arkr;