import { useEffect, useState } from "react";
import { Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, Divider, Grid, MenuItem, Switch, ToggleButtonGroup, Typography } from "@mui/material";
import { isNotEmpty } from "../utils/validations";
import dayjs from "dayjs";
import PodoDialogTitle from "../components/PodoDialogTitle";
import PodoDialogContent from "../components/PodoDialogContent";
import DraggableHeader from "../components/DraggableHeader";
import { useUtils } from "../contexts/UtilContext";
import { useSnackbar } from "../contexts/SnackbarContext";
import { useForm } from "../hooks/useForm";
import HelpTooltip from "../components/HelpTooltip";
import PropTimePicker from "../components/device/PropTimePicker";
import { useAuth } from "../contexts/AuthContext";
import PropToggleButton from "../components/device/PropToggleButton";
import { useCode } from "../contexts/CodeContext";
import CompactTextField from "../components/CompactTextField";
import { grey } from "@mui/material/colors";
import { weekDays } from "../utils/utils";
import PropLabel from "../components/device/PropLabel";
import Led from "../components/Led";
import PropValue from "../components/device/PropValue";

const ArdmSetup = (props) => {
  const { codes } = useCode();
  const { user } = useAuth();
  const { api } = useUtils();
  const { showMessage } = useSnackbar();
  const node = props.node;
  const dvc = props.dvc;
  const data = dvc.data;

  const isAdmin = Array.isArray(user?.roles) && user.roles.includes('ROLE_ADMIN');

  const { values, handleChange, touched, errors, setValues, resetForm, isValid } = useForm({
    // chkWd: '0'
  }, []);
  const [ isLoading, setIsLoading ] = useState(false);

  useEffect(() => {
    if (props.open) {
      setValues({...data,
        oprtStHm: isNotEmpty(data?.oprtStHm) ? dayjs(data?.oprtStHm, "HHmm") : null,
        oprtEdHm: isNotEmpty(data?.oprtEdHm) ? dayjs(data?.oprtEdHm, "HHmm") : null,
        chkStHm: isNotEmpty(data?.chkStHm) ? dayjs(data?.chkStHm, "HHmm") : null,
        chkEdHm: isNotEmpty(data?.chkEdHm) ? dayjs(data?.chkEdHm, "HHmm") : null,
      })
    } else {
      resetForm({})
    }
  }, [props.open]);

  //적용
  const handleApply = () => {
    const param = !!values.auto ? {
      auto: values.auto,
      oprtStHm: dayjs.isDayjs(values.oprtStHm) ? values.oprtStHm.format('HHmm') : null,
      oprtEdHm: dayjs.isDayjs(values.oprtEdHm) ? values.oprtEdHm.format('HHmm') : null,
      autoFineDust: values.autoFineDust,
      oprtStFineDust: values.oprtStFineDust,
      autoUfineDust: values.autoUfineDust,
      oprtStUfineDust: values.oprtStUfineDust,
      autoOtdrTemp: values.autoOtdrTemp,
      oprtStOtdrTemp: values.oprtStOtdrTemp,
      autoBadWthr: values.autoBadWthr,
      autoBadWind: values.autoBadWind,

      raHopeTemp: values.raHopeTemp,
      setEvapFan: values.setEvapFan,
      evapHopeFan: values.evapHopeFan,
      autoChk: values.autoChk,
      chkWd: values.chkWd,
      chkStHm: dayjs.isDayjs(values.chkStHm) ? values.chkStHm.format('HHmm') : null,
      chkEdHm: dayjs.isDayjs(values.chkEdHm) ? values.chkEdHm.format('HHmm') : null,
    } : {
      auto: values.auto,
      oprt: values.oprt,
      oprtMode: values.oprtMode,

      raHopeTemp: values.raHopeTemp,
      setEvapFan: values.setEvapFan,
      evapHopeFan: values.evapHopeFan,
      autoChk: values.autoChk,
      chkWd: values.chkWd,
      chkStHm: dayjs.isDayjs(values.chkStHm) ? values.chkStHm.format('HHmm') : null,
      chkEdHm: dayjs.isDayjs(values.chkEdHm) ? values.chkEdHm.format('HHmm') : null,
    };

    setIsLoading(true);
    api.post(`/api/devices/${dvc.dvcId}/update`, param).then(response => {
      showMessage(response?.message || '적용되었습니다.');
      props.handleClose();
      setIsLoading(false);
    }).catch(e => {
      if (e.response?.status === 401) props.handleClose();
      showMessage(`[${e.response?.status}] ${e.message}`, 'error');
      setIsLoading(false);
    });
  }

  return (
    <Dialog open={props.open} PaperComponent={DraggableHeader} fullWidth maxWidth="md">
      <PodoDialogTitle text={'설정: ' + dvc.dvcNm} onClose={() => props.handleClose()}/>
      <PodoDialogContent>
        <Grid container spacing={2} justifyContent="space-evenly">
          <Grid item xs={12} container justifyContent="center" alignItems="center">
            <Typography variant="subtitle2">수동</Typography>
            <Switch name="auto" label="자동"
              checked={!!values.auto}
              onChange={handleChange}
              color="primary"
              size="small" />
            <Typography variant="subtitle2">자동</Typography>
            <HelpTooltip>
              제어모드를 자동으로 설정하면 운영시간 및 설정 조건에 따라 에어돔이 자동으로 제어됩니다.
            </HelpTooltip>
          </Grid>
          <Grid item xs={12} sm={2.5} container justifyContent="center" alignItems="center">
            <Typography variant="subtitle2">OFF</Typography>
            <Switch name="oprt" label="운전"
              checked={!!values.oprt}
              onChange={e => handleChange({target:{name: e.target.name, value: e.target.checked}})}
              color="warning"
              size="small"
              disabled={!!values.auto} />
            <Typography variant="subtitle2">ON</Typography>
            <HelpTooltip>
              에어돔 운전에는 최대 3분 가량이 소요됩니다.<br/>
              또한, 에어돔 운전 정지에는 최대 1분 가량이 소요됩니다.
            </HelpTooltip>
          </Grid>
          <Grid item xs={12} sm={4.5} container justifyContent="center" alignItems="center">
            <ToggleButtonGroup value={values.oprtMode} exclusive onChange={(e, value) => handleChange({target: {name: 'oprtMode', value: value}})} size="small" disabled={!!values.auto || !isAdmin} title={!!values.auto ? "자동모드에서는 설정할 수 없습니다." : ""}>
              {codes.ArdmOprtMode?.filter(code => code.active).map((v, i) => <PropToggleButton key={i} value={v.code} disabled={!v.active} sx={{px: 3}}>{v.label}</PropToggleButton>)}
            </ToggleButtonGroup>
            <HelpTooltip>
              운전모드는 항상 자동으로 지정됩니다.<br/>
              대개 기온이 낮을 때에는 냉방운전을 하지 않습니다.
            </HelpTooltip>
          </Grid>
          <Grid item xs={12} sm={5} container justifyContent="center" alignItems="center">
            <PropTimePicker name="oprtStHm" label="운영시간(시작)"
              value={values.oprtStHm}
              onChange={value => handleChange(value)}
              disabled={!values.auto}
               />
            <Typography sx={{mx: 1}}>~</Typography>
            <PropTimePicker name="oprtEdHm" label="운영시간(종료)"
              value={values.oprtEdHm}
              onChange={value => handleChange(value)}
              disabled={!values.auto}
               />
            <HelpTooltip>
              운영시간 내에서 운전조건에 해당될 때에만 자동으로 운전 또는 운전정지됩니다.
            </HelpTooltip>
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Divider sx={{width: '100%'}} />
          </Grid>
          <Grid item xs={12} sm={4} container justifyContent="start">
            <Box display="inline-flex" alignItems="center">
              <Checkbox name="autoFineDust" size="small" checked={!!values.autoFineDust} onChange={handleChange} disabled={ !values.auto } />
              <Typography variant="subtitle2" sx={{mr: 2, color: !!values.auto ? 'inherit' : grey[500]}}>미세먼지</Typography>
              <CompactTextField name="oprtStFineDust"
                value={values.oprtStFineDust}
                onChange={handleChange}
                size='small'
                sx={{ maxWidth: 50, textAlign: 'right', my: 0}}
                inputProps={{ maxLength: 3 }}
                margin="dense"
                disabled={ !values.auto }
               />
               <Typography variant="subtitle2" sx={{ml: 1, color: !!values.auto ? 'inherit' : grey[500]}}>이상이면 운전</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} container justifyContent="start">
            <Box display="inline-flex" alignItems="center">
              <Checkbox name="autoUfineDust" size="small" checked={!!values.autoUfineDust} onChange={handleChange} disabled={ !values.auto } />
              <Typography variant="subtitle2" sx={{mr: 2, color: !!values.auto ? 'inherit' : grey[500]}}>초미세먼지</Typography>
              <CompactTextField name="oprtStUfineDust"
                value={values.oprtStUfineDust}
                onChange={handleChange}
                size='small'
                sx={{ maxWidth: 50, textAlign: 'right', my: 0}}
                inputProps={{ maxLength: 3 }}
                margin="dense"
                disabled={ !values.auto }
               />
               <Typography variant="subtitle2" sx={{ml: 1, color: !!values.auto ? 'inherit' : grey[500]}}>이상이면 운전</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} container justifyContent="start">
            <Box display="inline-flex" alignItems="center">
              <Checkbox name="autoOtdrTemp" size="small" checked={!!values.autoOtdrTemp} onChange={handleChange} disabled={ !values.auto } />
              <Typography variant="subtitle2" sx={{mr: 2, color: !!values.auto ? 'inherit' : grey[500]}}>외부기온</Typography>
              <CompactTextField name="oprtStOtdrTemp"
                value={values.oprtStOtdrTemp}
                onChange={handleChange}
                size='small'
                sx={{ maxWidth: 50, textAlign: 'right', my: 0}}
                inputProps={{ maxLength: 3 }}
                margin="dense"
                disabled={ !values.auto }
               />
               <Typography variant="subtitle2" sx={{ml: 1, color: !!values.auto ? 'inherit' : grey[500]}}>℃ 이상이면 운전</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} container justifyContent="start">
            <Box display="inline-flex" alignItems="center">
              <Checkbox name="autoBadWthr" size="small" checked={!!values.autoBadWthr} onChange={handleChange} disabled={ !values.auto } />
              <Typography variant="subtitle2" sx={{mr: 2, color: !!values.auto ? 'inherit' : grey[500]}}>눈/비 올 때는 운전 정지</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} container justifyContent="start">
            <Box display="inline-flex" alignItems="center">
              <Checkbox name="autoBadWind" size="small" checked={!!values.autoBadWind} onChange={handleChange} disabled={ !values.auto } />
              <Typography variant="subtitle2" sx={{mr: 2, color: !!values.auto ? 'inherit' : grey[500]}}>바람 많을 경우 운전 정지</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} container justifyContent="start">
            <Box display="inline-flex" alignItems="center">
              <Typography variant="subtitle2" sx={{mr: 2}}>내부온도</Typography>
              <CompactTextField name="raHopeTemp"
                value={values.raHopeTemp}
                onChange={handleChange}
                size='small'
                sx={{ maxWidth: 50, textAlign: 'right', my: 0}}
                inputProps={{ maxLength: 3 }}
                margin="dense"
               />
               <Typography variant="subtitle2" sx={{ml: 1}}>℃ 이하면 송풍 운전</Typography>
               <HelpTooltip>
                운전조건에 해당하더라도 에어돔 내부 공간이 지정한 온도 이하가 되면 냉각을 중지하고 송풍운전하게 됩니다.<br/>
                에어컨의 '희망온도'와 비슷한 의미입니다. 
              </HelpTooltip>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} container justifyContent="start">
            <Box display="inline-flex" alignItems="center">
              <Checkbox name="setEvapFan" size="small" checked={!!values.setEvapFan} onChange={handleChange} />
              <Typography variant="subtitle2" sx={{mr: 2}}>FAN 속도</Typography>
              <CompactTextField name="evapHopeFan"
                value={values.evapHopeFan}
                onChange={handleChange}
                size='small'
                sx={{ maxWidth: 50, textAlign: 'right', my: 0}}
                inputProps={{ maxLength: 3 }}
                margin="dense"
                disabled={ !values.setEvapFan }
               />
               <Typography variant="subtitle2" sx={{ml: 1}}>%로 고정</Typography>
               <HelpTooltip>
                에어컨의 '바람세기'와 비슷합니다.<br/>
                에어돔은 FAN속도를 자동으로 제어하지만 필요한 경우 원하는 FAN속도를 지정할 수 있습니다.<br/>
                지나치게 낮거나 높게 설정하면 운전효율이 나빠집니다.<br/>
                적정한 값은 50% ~ 70% 입니다. 
              </HelpTooltip>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} container justifyContent="start">
            <Box display="inline-flex" alignItems="center">
              <Checkbox name="autoChk" size="small" checked={!!values.autoChk} onChange={handleChange} />
              <Typography variant="subtitle2" sx={{mr: 2}}>자동 점검운전 :</Typography>
              <CompactTextField name='chkWd' label="점검요일"
                select
                value={values.chkWd || '0'}
                onChange={handleChange}
                size='small'
                sx={{minWidth: 85, mr: 1}}
                margin="dense"
                autoComplete="off" >
                { weekDays.map((code, index) => <MenuItem key={index} value={code.code} dense>{code.label}</MenuItem>)}
              </CompactTextField>
              <PropTimePicker name="chkStHm" label="점검시간(시작)"
                value={values.chkStHm}
                onChange={value => handleChange(value)}
                />
              <Typography sx={{mx: 1}}>~</Typography>
              <PropTimePicker name="chkEdHm" label="점검시간(종료)"
                value={values.chkEdHm}
                onChange={value => handleChange(value)}
                />
               <HelpTooltip>
                대기가 깨끗하거나 기온이 낮은 계절에는 에어돔이 오랫동안 가동되지 않을 수 있는데,<br/>
                기계적인 기능유지를 위해 매주 한번씩 점검운전하도록 예약할 수 있습니다. 
              </HelpTooltip>
            </Box>
          </Grid>


          { isAdmin && <>
          <Grid item xs={12} container justifyContent="center">
            <Divider sx={{width: '100%'}} />
          </Grid>
          <Grid item xs={7} container justifyContent="center" sx={{bgcolor: grey[200]}}>
            <Grid item xs={12/7} textAlign="center">
              <PropLabel>압축기</PropLabel>
              <Led value={!!values.comp} size="small" />
            </Grid>
            <Grid item xs={12/7} textAlign="center">
              <PropLabel>사방변밸브</PropLabel>
              <Led value={!!values.fwvv} size="small" />
            </Grid>
            <Grid item xs={12/7} textAlign="center">
              <PropLabel>증발기히터</PropLabel>
              <Led value={!!values.evap} size="small" />
            </Grid>
            <Grid item xs={12/7} textAlign="center">
              <PropLabel>응축기히터</PropLabel>
              <Led value={!!values.cond} size="small" />
            </Grid>
            <Grid item xs={12/7} textAlign="center">
              <PropLabel>바이패스밸브</PropLabel>
              <Led value={!!values.byps} size="small" />
            </Grid>
            <Grid item xs={12/7} textAlign="center">
              <PropLabel>CC히터</PropLabel>
              <Led value={!!values.ccht} size="small" />
            </Grid>
            <Grid item xs={12/7} textAlign="center">
              <PropLabel>드레인열선</PropLabel>
              <Led value={!!values.drht} size="small" />
            </Grid>
          </Grid>
          <Grid item xs={5} container justifyContent="center" sx={{bgcolor: grey[200]}}>
            <Grid item xs={12/4} textAlign="center">
              <PropLabel>외기온도</PropLabel>
              <PropValue unit="℃">{ values.otdrTemp || '-' }</PropValue>
            </Grid>
            <Grid item xs={12/4} textAlign="center">
              <PropLabel>델타T</PropLabel>
              <PropValue unit="℃">{ values.dtTemp || '-' }</PropValue>
            </Grid>
            <Grid item xs={12/4} textAlign="center">
              <PropLabel>RA온도</PropLabel>
              <PropValue unit="℃">{ values.raTemp || '-' }</PropValue>
            </Grid>
            <Grid item xs={12/4} textAlign="center">
              <PropLabel>RA습도</PropLabel>
              <PropValue unit="%RH">{ values.raHmdt || '-' }</PropValue>
            </Grid>
          </Grid>
          <Grid item xs={7} container justifyContent="center" sx={{bgcolor: grey[200]}}>
            <Grid item xs={12/7} textAlign="center">
              <PropLabel>압축기</PropLabel>
              <Led value={!!(values.err & 0b0000000000000001)} size="small" color="error.light" blink />
            </Grid>
            <Grid item xs={12/7} textAlign="center">
              <PropLabel>인버터통신</PropLabel>
              <Led value={!!(values.err & 0b0010000000000000)} size="small" color="error.light" blink />
            </Grid>
            <Grid item xs={12/7} textAlign="center">
              <PropLabel>누수</PropLabel>
              <Led value={!!(values.err & 0b0000000000000010)} size="small" color="error.light" blink />
            </Grid>
            <Grid item xs={12/7} textAlign="center">
              <PropLabel>HALON</PropLabel>
              <Led value={!!(values.err & 0b0000000000000100)} size="small" color="error.light" blink />
            </Grid>
            <Grid item xs={12/7} textAlign="center">
              <PropLabel>고압</PropLabel>
              <Led value={!!(values.err & 0b0000000000001000)} size="small" color="error.light" blink />
            </Grid>
            <Grid item xs={12/7} textAlign="center">
              <PropLabel>저압</PropLabel>
              <Led value={!!(values.err & 0b0000000000010000)} size="small" color="error.light" blink />
            </Grid>
            <Grid item xs={12/7} textAlign="center">
              <PropLabel>비상정지</PropLabel>
              <Led value={!!(values.err & 0b0000000000100000)} size="small" color="error.light" blink />
            </Grid>
          </Grid>
          <Grid item xs={5} container justifyContent="center" sx={{bgcolor: grey[200]}}>
            <Grid item xs={12/4} textAlign="center">
              <PropLabel>증발기입구</PropLabel>
              <PropValue unit="℃">{ values.evapEntTemp || '-' }</PropValue>
            </Grid>
            <Grid item xs={12/4} textAlign="center">
              <PropLabel>증발기출구</PropLabel>
              <PropValue unit="℃">{ values.evapExtTemp || '-' }</PropValue>
            </Grid>
            <Grid item xs={12/4} textAlign="center">
              <PropLabel>증발기FAN</PropLabel>
              <PropValue unit="%">{ values.evapFan || '-' }</PropValue>
            </Grid>
            <Grid item xs={12/4} textAlign="center">
              <PropLabel>응축기FAN</PropLabel>
              <PropValue unit="%">{ values.condFan || '-' }</PropValue>
            </Grid>
          </Grid>
          <Grid item xs={7} container justifyContent="center" sx={{bgcolor: grey[200]}}>
            <Grid item xs={12/7} textAlign="center">
              <PropLabel>증발기입구</PropLabel>
              <Led value={!!(values.err & 0b0000000010000000)} size="small" color="error.light" blink />
            </Grid>
            <Grid item xs={12/7} textAlign="center">
              <PropLabel>증발기출구</PropLabel>
              <Led value={!!(values.err & 0b0000000100000000)} size="small" color="error.light" blink />
            </Grid>
            <Grid item xs={12/7} textAlign="center">
              <PropLabel>압축기입구</PropLabel>
              <Led value={!!(values.err & 0b0000001000000000)} size="small" color="error.light" blink />
            </Grid>
            <Grid item xs={12/7} textAlign="center">
              <PropLabel>압축기출구</PropLabel>
              <Led value={!!(values.err & 0b0000010000000000)} size="small" color="error.light" blink />
            </Grid>
            <Grid item xs={12/7} textAlign="center">
              <PropLabel>외기온도센서</PropLabel>
              <Led value={!!(values.err & 0b0000100000000000)} size="small" color="error.light" blink />
            </Grid>
            <Grid item xs={12/7} textAlign="center">
              <PropLabel>RA센서</PropLabel>
              <Led value={!!(values.err & 0b0001000000000000)} size="small" color="error.light" blink />
            </Grid>
            <Grid item xs={12/7} textAlign="center">
              <PropLabel>RA통신</PropLabel>
              <Led value={!!(values.err & 0b1000000000000000)} size="small" color="error.light" blink />
            </Grid>
          </Grid>
          <Grid item xs={5} container justifyContent="center" sx={{bgcolor: grey[200]}}>
            <Grid item xs={12/4} textAlign="center">
              <PropLabel>고압</PropLabel>
              <PropValue unit="bar">{ values.compHp || '-' }</PropValue>
            </Grid>
            <Grid item xs={12/4} textAlign="center">
              <PropLabel>저압</PropLabel>
              <PropValue unit="bar">{ values.compLp || '-' }</PropValue>
            </Grid>
            <Grid item xs={12/4} textAlign="center">
              <PropLabel>압축기출력</PropLabel>
              <PropValue unit="%">{ values.compOut || '-' }</PropValue>
            </Grid>
            <Grid item xs={12/4} textAlign="center">
              <PropLabel>압축기RPM</PropLabel>
              <PropValue unit="">{ values.compRpm || '-' }</PropValue>
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent="end" sx={{bgcolor: grey[200]}}>
            <Grid item xs={5/4} textAlign="center">
              <PropLabel>펌웨어</PropLabel>
              <PropValue>{ values.ver || '-' }</PropValue>
            </Grid>
            <Grid item xs={5/4} textAlign="center">
              <PropLabel>운전적산</PropLabel>
              <PropValue unit="시간">{ values.oprtAge || '-' }</PropValue>
            </Grid>
            <Grid item xs={5/4} textAlign="center">
              <PropLabel>압축기적산</PropLabel>
              <PropValue unit="시간">{ values.compAge || '-' }</PropValue>
            </Grid>
          </Grid>
          </>
          }
        </Grid>
      </PodoDialogContent>
      <DialogActions>
        <Button variant="contained" disabled={!isValid || isLoading} onClick={handleApply}>
        {isLoading ? <CircularProgress size={24} color="inherit" /> : '적용'}
        </Button>
        <Button variant="contained" color="neutral" onClick={props.handleClose}>취소</Button>
      </DialogActions>
    </Dialog>
  )
};

export default ArdmSetup;