import { createContext, useContext, useMemo } from "react";
import { useStorage } from "../hooks/useStorage";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useStorage('user', null);
  const [jwt, setJwt] = useStorage('jwt', null);

  // const login = async (jwt, data, url) => {
  //   setJwt(jwt);
  //   setUser(data);
  //   !!url && navigate(url);
  // };

  const login = async ({token, user, targetUrl}) => {
  try {
    setJwt(token);
    setUser(user);

    return targetUrl || "/";
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
}

  // call this function to sign out logged in user
  const logout = (navigate) => {
    setJwt(null);
    setUser(null);
  };

  const value = useMemo(
    () => ({
      jwt,
      user,
      login,
      logout
    }),
    [jwt, user]
  );

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
