import { Refresh } from "@mui/icons-material";
import { Box, Button, CardHeader, Grid, IconButton, Typography } from "@mui/material";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useUtils } from "../../contexts/UtilContext";
import { useSnackbar } from "../../contexts/SnackbarContext";
import { useDialogs } from "../../contexts/DialogContext";
import { grey } from "@mui/material/colors";

const DeviceCardHeader = ( props ) => {
  const { api } = useUtils();
  const { showMessage } = useSnackbar();
  const { openDialog } = useDialogs();

  const node = props.node;
  const dvc = props.dvc;
  const on = props?.on === false ? false : true;
  const bgcolor = on ? 'primary.main' : grey[300];
  const color = on ? 'common.white' : grey[700];

  const handleRefresh = (dvc) => {
    api.get(`/api/devices/${dvc.dvcId}/refresh`, {})
      .then(response => {
      }).catch((e) => {
        showMessage(`[${e.response?.status}] ${e.message}`, 'error');
      });
  };

  const handleSetup = () => {
    openDialog(dvc.dvcTypeCd.toLowerCase() + 'Setup', {
      node: node,
      dvc: dvc
    });
  };

  const displayAction = () => {
    return (
      <>
        { props.setup &&
          <Button variant="text" size="small" startIcon={<SettingsOutlinedIcon fontSize="small" sx={{mr: -.5}}/>} onClick={handleSetup} sx={{ color: color, mr: 1, lineHeight: '1rem' }}>설정</Button>
        }
      </>
    );
  };

  return (
    <CardHeader title={<Typography variant="h7">{dvc.dvcNm}</Typography>}
      action={displayAction()}
      sx={{p: 0.5, color: color, bgcolor: bgcolor, textAlign: 'center', alignItems: 'center', '& .MuiCardHeader-action': {alignSelf: 'center'}}}
      color="primary" />
  );
};

export default DeviceCardHeader;