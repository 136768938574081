import { Box, Chip, Typography } from "@mui/material";
import { Square } from "@mui/icons-material";

const Color = (props) => {
  const color = props.color || "#000000";

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="1.6em" sx={{ color: color}}>
      <Square fontSize="small" sx={{ border: "#00000014 1px solid"}}/>
    </Box>
  );
}

export default Color;