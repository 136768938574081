import { useEffect, useState } from "react";
import { Button, CircularProgress, Dialog, DialogActions, Grid, Switch, Typography } from "@mui/material";
import { isNotEmpty } from "../utils/validations";
import dayjs from "dayjs";
import PodoDialogTitle from "../components/PodoDialogTitle";
import PodoDialogContent from "../components/PodoDialogContent";
import DraggableHeader from "../components/DraggableHeader";
import { useUtils } from "../contexts/UtilContext";
import { useSnackbar } from "../contexts/SnackbarContext";
import { useForm } from "../hooks/useForm";
import HelpTooltip from "../components/HelpTooltip";
import PropTimePicker from "../components/device/PropTimePicker";

const LdltSetup = (props) => {

  const { api } = useUtils();
  const { showMessage } = useSnackbar();
  const node = props.node;
  const dvc = props.dvc;
  const data = dvc.data;

  const { values, handleChange, resetForm, isValid } = useForm({}, []);
  const [ isLoading, setIsLoading ] = useState(false);

  useEffect(() => {
    if (props.open) {
      resetForm({...data,
        oprtStHm: isNotEmpty(data?.oprtStHm) ? dayjs(data?.oprtStHm, "HHmm") : null,
        oprtEdHm: isNotEmpty(data?.oprtEdHm) ? dayjs(data?.oprtEdHm, "HHmm") : null,
        oprtStHm2: isNotEmpty(data?.oprtStHm2) ? dayjs(data?.oprtStHm2, "HHmm") : null,
        oprtEdHm2: isNotEmpty(data?.oprtEdHm2) ? dayjs(data?.oprtEdHm2, "HHmm") : null,
      })
    }
  }, [props.open]);

  //적용
  const handleApply = () => {
    const param = !!values.auto ? {
      auto: values.auto,
      oprtStHm: dayjs.isDayjs(values.oprtStHm) ? values.oprtStHm.format('HHmm') : null,
      oprtEdHm: dayjs.isDayjs(values.oprtEdHm) ? values.oprtEdHm.format('HHmm') : null,
      oprtStHm2: dayjs.isDayjs(values.oprtStHm2) ? values.oprtStHm2.format('HHmm') : null,
      oprtEdHm2: dayjs.isDayjs(values.oprtEdHm2) ? values.oprtEdHm2.format('HHmm') : null
    } : {
      auto: values.auto,
      pwr: values.pwr
    };

    setIsLoading(true);
    api.post(`/api/devices/${dvc.dvcId}/update`, param).then(response => {
      showMessage(response?.message || '적용되었습니다.');
      props.handleClose();
      setIsLoading(false);
    }).catch(e => {
      if (e.response?.status === 401) props.handleClose();
      showMessage(`[${e.response?.status}] ${e.message}`, 'error');
      setIsLoading(false);
    });
  }

  return (
    <Dialog open={props.open} PaperComponent={DraggableHeader}>
      <PodoDialogTitle text={'설정: ' + dvc.dvcNm} onClose={() => props.handleClose()}/>
      <PodoDialogContent>
        <Grid container spacing={2} justifyContent="space-evenly">
          <Grid item xs={12} container justifyContent="center" alignItems="center">
            <Typography variant="subtitle2">수동</Typography>
            <Switch name="auto" label="자동"
              checked={!!values.auto}
              onChange={handleChange}
              color="primary"
              size="small" />
            <Typography variant="subtitle2">자동</Typography>
            <HelpTooltip>자동으로 설정하면 운영시간에 따라 디바이스가 자동으로 작동됩니다.</HelpTooltip>
          </Grid>
          <Grid item xs={12} container justifyContent="center" alignItems="center">
            <Typography variant="subtitle2">OFF</Typography>
            <Switch name="pwr" label="전원"
              checked={!!values.pwr}
              onChange={handleChange}
              color="warning"
              size="small"
              disabled={!!values.auto} />
            <Typography variant="subtitle2">ON</Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="center" alignItems="center">
            <PropTimePicker name="oprtStHm" label="운영시간(시작)"
              value={values.oprtStHm}
              onChange={value => handleChange(value)}
              disabled={!values.auto}
               />
            <Typography sx={{mx: 1}}>~</Typography>
            <PropTimePicker name="oprtEdHm" label="운영시간(종료)"
              value={values.oprtEdHm}
              onChange={value => handleChange(value)}
              disabled={!values.auto}
               />
          </Grid>
          <Grid item xs={12} container justifyContent="center" alignItems="center">
            <PropTimePicker name="oprtStHm2" label="운영시간2(시작)"
              value={values.oprtStHm2}
              onChange={value => handleChange(value)}
              disabled={!values.auto}
               />
            <Typography sx={{mx: 1}}>~</Typography>
            <PropTimePicker name="oprtEdHm2" label="운영시간2(종료)"
              value={values.oprtEdHm2}
              onChange={value => handleChange(value)} disabled={!values.auto}
               />
          </Grid>
        </Grid>
      </PodoDialogContent>
      <DialogActions>
        <Button variant="contained" disabled={!isValid || isLoading} onClick={handleApply}>
        {isLoading ? <CircularProgress size={24} color="inherit" /> : '적용'}
        </Button>
        <Button variant="contained" color="neutral" onClick={props.handleClose}>취소</Button>
      </DialogActions>
    </Dialog>
  )
};

export default LdltSetup;