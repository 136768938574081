import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { UtilsProvider } from './contexts/UtilContext';
import { DialogProvider } from './contexts/DialogContext';

import Login from './pages/Login';
import Main from './pages/Main';
import { PublicRoute } from './components/PublicRoute';
import { ProtectedRoute } from './components/ProtectedRoute';
import Error403 from './pages/Error403';
import Error404 from './pages/Error404';
import Admin from './pages/Admin';
import AdminNodes from './pages/AdminNodes';
import AdminUsers from './pages/AdminUsers';
import Nodes from './pages/Nodes';

const App = () => {

  return (
    <Router>
      <UtilsProvider>
        <DialogProvider>
          <Routes>
            <Route path="/login" element={<PublicRoute><Login /></PublicRoute>}/>
            <Route path="/main" element={<ProtectedRoute roles={['ROLE_USER', 'ROLE_ADMIN']}><Main /></ProtectedRoute>}>
              <Route path="nodes" element={<Nodes/>} />
            </Route>
            <Route path="/admin" element={<ProtectedRoute roles={['ROLE_ADMIN']}><Admin /></ProtectedRoute>}>
              <Route path="nodes" element={<AdminNodes/>} />
              <Route path="users" element={<AdminUsers/>} />
            </Route>
            <Route path="/accessDenied" element={<Error403 />}/> 
            <Route path="/" element={<Navigate replace to="/main"/>}/> 
            <Route path="*" element={<Error404/>}/> 
          </Routes>
        </DialogProvider>
      </UtilsProvider>
    </Router>
  );
};

export default App;