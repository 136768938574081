import { Box, Dialog, DialogContent, Link } from "@mui/material";
import DraggableHeader from "../components/DraggableHeader";
import AdminDataGrid from "../components/AdminDataGrid";
import { useEffect, useState } from "react";
import { useUtils } from "../contexts/UtilContext";
import { useSnackbar } from "../contexts/SnackbarContext";
import PodoDialogTitle from "../components/PodoDialogTitle";
import { useForm } from "../hooks/useForm";
import CompactTextField from "../components/CompactTextField";
import HelpTooltip from "../components/HelpTooltip";

const WthrCoodSearch = (props) => {
  const { showMessage } = useSnackbar();
  const { api, formatNumber } = useUtils();
  const [ rows, setRows ] = useState([]);
  const node = props.node;

  const { values, handleChange, resetForm, isValid } = useForm({
    hjNm: ''
  }, []);
  
  const handleRead = () => {
    api.post('/api/common/hjcds', values)
      .then(response => {
        const list = response.data?.data;
        if (Array.isArray(list)) {
          list.forEach(hj => {
            hj.dist = calculateDistance(hj);
          });
        }
        setRows(list.sort((a, b) => a.dist - b.dist));
      })
      .catch(e => {
        showMessage(`[${e.response?.status}] ${e.message}`, 'error')
      });
  };

  const handleSelect = (data) => {
    props.handleClose(data);
  };

  const radians = (degree) => {
    return Math.PI / 180 * degree
  }

  const calculateDistance = (data) => {
    return 6371 * Math.acos(Math.cos(radians(90 - node?.instlLattd)) * Math.cos(radians(90 - data.lattd)) + Math.sin(radians(90 -node?.instlLattd)) * Math.sin(radians(90 - data.lattd)) * Math.cos(radians(node?.instlLngtd - data.lngtd)));
  }

  useEffect(() => {
    if (props.open) {
      handleRead();
    } else {
      resetForm({
        hjNm: ''
      })
    }
  }, [props.open]);

  const columns = [
    { field: 'nx',  headerName: 'X좌표', type: 'number', align: 'center', flex: 2, renderCell: data => (<Link component="button" onClick={() => handleSelect(data.row)}>{data.row.nx}</Link>)},
    { field: 'ny',  headerName: 'Y좌표', type: 'number', align: 'center', flex: 2, renderCell: data => (<Link component="button" onClick={() => handleSelect(data.row)}>{data.row.ny}</Link>)},
    { field: 'hjCd', headerName: '코드', type: 'string', align: 'center', flex: 4},
    { field: 'hjNm1',  headerName: '1단계', type: 'string', align: 'left', flex: 3},
    { field: 'hjNm2',  headerName: '2단계', type: 'string', align: 'left', flex: 4},
    { field: 'hjNm3',  headerName: '3단계', type: 'string', align: 'left', flex: 5},
    { field: 'dist',  headerName: '거리(km)', type: 'number', align: 'center', flex: 2, valueFormatter: value => formatNumber(value, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + "km"},
  ]

  return (
    <Dialog open={props.open} PaperComponent={DraggableHeader} fullWidth maxWidth="md">
      <PodoDialogTitle text="동네 검색" onClose={() => props.handleClose()}/>
      <DialogContent sx={{mt: 2, pb: 0}}>
        <Box display='flex' alignItems="center">
          <CompactTextField name="hjNm" label="명칭검색"
              value={values.hjNm}
              onChange={handleChange}
              onKeyDown={e => e.key === 'Enter' && handleRead()}
              size='small'
              sx={{ width: 200}}
              inputProps={{ maxLength: 10 }}
              margin="dense"
               />
          <HelpTooltip>검색어 입력 후 Enter 하여 검색합니다.</HelpTooltip>
        </Box>

        <Box sx={{ height: '60vh', mb: 2}}>
          <AdminDataGrid
            rows={rows}
            columns={columns}
            getRowId={row => row.hjCd}
            density="compact"
            disableColumnMenu
            
          />
      </Box>
      </DialogContent>
    </Dialog>
  )
};

export default WthrCoodSearch;