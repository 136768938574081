import { FormControlLabel, styled } from "@mui/material";

const CompactFormControlLabel = styled(FormControlLabel)({
  '& label.MuiFormControlLabel-root': {
    fontSize: 20,
    marginRight: 8
  },
  '& span.MuiSwitch-root': {
    fontSize: 14
  },
  '& span.MuiTypography-root': {
    fontSize: 14,
    marginRight: 4
  },
  '& .MuiCheckbox-root': {
    paddingTop: 4,
    paddingBottom: 4
  },
  '& .MuiRadio-root' : {
    paddingTop: 4,
    paddingBottom: 4,
  }
});

export default CompactFormControlLabel;