import { styled } from "@mui/material";
import { grey } from "@mui/material/colors";
import { DataGrid } from "@mui/x-data-grid";

const AdminDataGrid = styled(DataGrid)(({ theme }) => ({
  
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaders, .MuiDataGrid-columnHeaderRow': {
    backgroundColor:'#ddd'  //컬럼헤더 배경색
  },
  '& .MuiDataGrid-iconSeparator': {
    color: '#bbb' //컬럼 구분자 색상
  },
  '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
    display: 'none' //마지막 컬럼의 구분자는 숨기기
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    justifyContent: 'center'  //컬럼 타이틀은 가운데 정렬
  },
  WebkitFontSmoothing: 'auto',
  borderColor: theme.palette.neutral.light,
  marginBottom: '2em',
  width: '100%'
}));

export default AdminDataGrid;

/*
border: 0,
  color:
    theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  '& .MuiDataGrid-columnsContainer': {
    backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    borderRight: `1px solid ${
      theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
    }`,
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${
      theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
    }`,
  },
  '& .MuiDataGrid-cell': {
    color:
      theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
  },
  '& .MuiPaginationItem-root': {
    borderRadius: 0,
  },
*/