import { Paper } from "@mui/material";
import { useRef } from "react";
import Draggable from "react-draggable";

const DraggableHeader = (props) => {
  const nodeRef = useRef(null);
  return (
    <Draggable nodeRef={nodeRef} cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  )
};

export default DraggableHeader;