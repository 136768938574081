import { Check, Close } from "@mui/icons-material"
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material"

export const Confirm = (props) => {
  const confirmMessage = Array.isArray(props.text) ? (
    props.text.map((text, index) => (
      <Typography key={index} variant="h6">{text}</Typography>
    ))
  ) : props.text;

  return (
    <Dialog open={props.open}>
    <DialogContent>
      <Grid container alignItems="center" spacing={2}>
        { !!props.icon && <Grid item>{props.icon}</Grid> }
        <Grid item>{confirmMessage}</Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button variant="text" color="neutral" startIcon={<Close sx={{mr: -.5}} />} onClick={props.onNo}>취소</Button>
      <Button variant="text" startIcon={<Check sx={{mr: -.5}}/>} onClick={props.onYes}>확인</Button>
    </DialogActions>
  </Dialog>
  )
}