import { DialogTitle, IconButton, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Close } from "@mui/icons-material";

const PodoDialogTitle = (props) => {
  const on = props?.on === false ? false : true;
  const bgcolor = on ? 'primary.main' : grey[300];
  const color = on ? 'common.white' : grey[700];
  
  return (
    <>
      <DialogTitle display='flex' alignItems='center' justifyContent="space-between"
        sx={{ p: 1, pl: 2, bgcolor: bgcolor, color: color}}>
        <Typography variant="h7">{props.text}</Typography>
        <Tooltip title="닫기">
          <IconButton aria-label="close"size="small"
            onClick={props.onClose} sx={{color: 'inherit'}}>
            <Close fontSize="small" />
          </IconButton>
        </Tooltip>
      </DialogTitle>
    </>
  )
}
export default PodoDialogTitle;