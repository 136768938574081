import { useEffect, useState } from "react";
import { Button, CircularProgress, Dialog, DialogActions, Grid, MenuItem } from "@mui/material";
import PodoDialogTitle from "../components/PodoDialogTitle";
import PodoDialogContent from "../components/PodoDialogContent";
import DraggableHeader from "../components/DraggableHeader";
import { useUtils } from "../contexts/UtilContext";
import { useSnackbar } from "../contexts/SnackbarContext";
import { useForm } from "../hooks/useForm";
import CompactTextField from "../components/CompactTextField";
import { useDialogs } from "../contexts/DialogContext";
import { useCode } from "../contexts/CodeContext";

const AwssSetup = (props) => {
  const { awsStns } = useCode();
  const { openDialog } = useDialogs();
  const { api } = useUtils();
  const { showMessage } = useSnackbar();
  const node = props.node;
  const dvc = props.dvc;
  const data = dvc.data;

  const { values, handleChange, handleChangeValues, resetForm, isValid } = useForm({}, []);
  const [ isLoading, setIsLoading ] = useState(false);

  useEffect(() => {
    if (props.open) {
      resetForm({...data})
    }
  }, [props.open]);

  //적용
  const handleApply = () => {
    const param = {
      stnId: values.stnId
    };

    setIsLoading(true);
    api.post(`/api/devices/${dvc.dvcId}/update`, param).then(response => {
      showMessage(response?.message || '적용되었습니다.');
      props.handleClose();
      setIsLoading(false);
    }).catch(e => {
      if (e.response?.status === 401) props.handleClose();
      showMessage(`[${e.response?.status}] ${e.message}`, 'error');
      setIsLoading(false);
    });
  }

  const handleAwsStnSearch = () => {
    openDialog('awsStnSearch', {
      node: node,
      callback: (data) => {
        handleChangeValues({stnId: data?.stnId});
      }
    });
  };

  return (
    <Dialog open={props.open} PaperComponent={DraggableHeader}>
      <PodoDialogTitle text={'설정: ' + dvc.dvcNm} onClose={() => props.handleClose()}/>
      <PodoDialogContent sx={{mb: 2}}>
        <Grid container spacing={2} justifyContent="space-evenly">
          <Grid item xs={12} container justifyContent="center" alignItems="center">
            <CompactTextField name='stnId' label="AWS측정소"
                select
                value={values.stnId || ''}
                onChange={handleChange}
                size='small'
                sx={{width: 150}}
                margin="dense"
                autoComplete="off" >
                { awsStns.map((stn, index) => <MenuItem key={index} value={stn.stnId} dense>{stn.stnKo}</MenuItem>)}
            </CompactTextField>
            <Button variant="contained" color="info" size="small" onClick={handleAwsStnSearch} sx={{ml: 2}}>
              측정소 검색
            </Button>
          </Grid>
        </Grid>
      </PodoDialogContent>
      <DialogActions>
        <Button variant="contained" disabled={!isValid || isLoading} onClick={handleApply}>
        {isLoading ? <CircularProgress size={24} color="inherit" /> : '적용'}
        </Button>
        <Button variant="contained" color="neutral" onClick={props.handleClose}>취소</Button>
      </DialogActions>
    </Dialog>
  )
};

export default AwssSetup;