import { Card, Grid } from "@mui/material";

import { useUtils } from "../../contexts/UtilContext";
import PropLabel from "./PropLabel";
import PropValue from "./PropValue";
import DeviceCardHeader from "./DeviceCardHeader";
import DeviceCardContent from "./DeviceCardContent";

const Arss = (props) => {
  const { formatNumber } = useUtils();
  const node = props.node;
  const dvc = props.dvc;
  const data = dvc.data;

  const size = props.mini ? 4 : 6;

  return (
    <Grid item xs={size}>
      <Card>
        <DeviceCardHeader node={node} dvc={dvc} on={true} />
        <DeviceCardContent>
          <Grid container alignItems='start' justifyContent="space-evenly">
            { props.mini ? <>
            <Grid item xs={3} textAlign='center'>
              <PropLabel>미세먼지</PropLabel>
              <PropValue unit="㎍/㎥">{formatNumber(data.fineDust) || '-'}</PropValue>
            </Grid>
            <Grid item xs={3} textAlign='center'>
              <PropLabel>초미세먼지</PropLabel>
              <PropValue unit="㎍/㎥">{formatNumber(data.ufineDust) || '-'}</PropValue>
            </Grid>
            <Grid item xs={3} textAlign='center'>
              <PropLabel>온도</PropLabel>
              <PropValue unit="℃">{formatNumber(data.temp, {minimumFractionDigits: 1, maximumFractionDigits: 1}) || '-'}</PropValue>
            </Grid>
            <Grid item xs={3} textAlign='center'>
              <PropLabel>습도</PropLabel>
              <PropValue unit="%RH">{formatNumber(data.hmdt, {minimumFractionDigits: 1, maximumFractionDigits: 1}) || '-'}</PropValue>
            </Grid>
            </>
            :
            <>
            <Grid item xs={2} textAlign='center'>
              <PropLabel>미세먼지</PropLabel>
              <PropValue unit="㎍/㎥">{formatNumber(data.fineDust) || '-'}</PropValue>
            </Grid>
            <Grid item xs={2} textAlign='center'>
              <PropLabel>초미세먼지</PropLabel>
              <PropValue unit="㎍/㎥">{formatNumber(data.ufineDust) || '-'}</PropValue>
            </Grid>
            <Grid item xs={2} textAlign='center'>
              <PropLabel>온도</PropLabel>
              <PropValue unit="℃">{formatNumber(data.temp, {minimumFractionDigits: 1, maximumFractionDigits: 1}) || '-'}</PropValue>
            </Grid>
            <Grid item xs={2} textAlign='center'>
              <PropLabel>습도</PropLabel>
              <PropValue unit="%RH">{formatNumber(data.hmdt, {minimumFractionDigits: 1, maximumFractionDigits: 1}) || '-'}</PropValue>
            </Grid>
            <Grid item xs={2} textAlign='center'>
              <PropLabel>CO2</PropLabel>
              <PropValue unit="ppm">{formatNumber(data.co2) || '-'}</PropValue>
            </Grid>
            <Grid item xs={2} textAlign='center'>
              <PropLabel>VOCS</PropLabel>
              <PropValue unit="ppb">{formatNumber(data.vocs) || '-'}</PropValue>
            </Grid>
            </>
            }
          </Grid>
        </DeviceCardContent>
      </Card>
    </Grid>
  );

}

export default Arss;